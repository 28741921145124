import React, { useEffect } from 'react'
import { Delete_user, Deactivate_all_users } from '../../../redux/services/AuthUser'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { get_all_users } from '../../../redux/services/AuthUser';
import Modal from 'react-modal';
const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        maxWidth: '750px',
        // width: '100%',
        background: '#fff',
        borderRadius: '8px',
        padding: '20px',
        height: '34%',
        zIndex: '99',
        transition: 'all 1s ease-in-out'
    }
};
const DeActivateUser = ({ id, modalIsOpen, setModalIsOpen, selectedUsers, type,setSelectedUsers,search }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onSubmit = async() => {
        if (id) {
            dispatch(Delete_user(id?.id));
            setModalIsOpen(false);
            navigate('/auth/users');
        } else if (type == 'Deactivate_All') {
            const payload = {
                selectedUsers: selectedUsers
            };
           await dispatch(Deactivate_all_users(payload));
            setModalIsOpen(false);
            setSelectedUsers('')
            // navigate('/auth/settings/users');
            dispatch(get_all_users(search))
        }
        else {
            setModalIsOpen(false);
        }
    }
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customModalStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Create Role Modal"
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header close-deactivate">
                        <button type="button" className="btn-close" onClick={() => setModalIsOpen(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="font-18 hd-title">Deactivate user(s)?</div>
                        <div className="hd-subtitle font-14">Are you sure you want to 
                        deactivate this user? This action cannot be undone.</div>

                        <div className="pop-action-btn">
                            <button type="button" className="btn btn-light" onClick={() => setModalIsOpen(false)}>Cancel</button>
                            <button type="submit" className="btn btn-danger mx-2" onClick={() => onSubmit()}>Deactivate</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeActivateUser