import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";


// appointment-with-list
export const get_appointment_with_list = createAsyncThunk(
    "auth/appointment-with-list",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/appointment-with-list`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            if(error?.response?.data?.message === 'read ECONNRESET' || error?.message === 'read ECONNRESET'){
                window.location.reload(); 
            }else{
                customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            }
        }
    }
);
//get  all-users-list
export const get_all_users_list = createAsyncThunk(
    "auth/all-users-list-mar",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-users-list-mar`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
           if(error?.response?.data?.message === 'read ECONNRESET' || error?.message === 'read ECONNRESET'){
                window.location.reload(); 
            }else{
                customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            }
        }
    }
);
// create-appointment
export const create_appointment = createAsyncThunk(
    "/api/create-appointment",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/create-appointment`,
                headers: {
                    // "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get  appointment-list
export const get_all_appointment_list = createAsyncThunk(
    "auth/appointment-list",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/appointment-list?member_id=${SearchData?.member_id}&page=${SearchData?.page}&listData=${SearchData?.listData}&approved_status=${SearchData.approved_status}&appointment_status=${SearchData.appointment_status}&type=${SearchData?.type?? ''}&date_range=${SearchData?.date_range?? ''}&searchQuery=${SearchData.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            if(error?.response?.data?.message === 'read ECONNRESET' || error?.message === 'read ECONNRESET'){
                window.location.reload(); 
            }else{
                customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            }
        }
    }
);
// appointment-change-status
export const appointment_change_status = createAsyncThunk(
    "/api/appointment-change-status",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/appointment-change-status`,
                headers: {
                    // "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status == true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get  appointment-edit
export const get_appointment_edit = createAsyncThunk(
    "auth/appointment-edit",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/appointment-edit?appointment_id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
           if(error?.response?.data?.message === 'read ECONNRESET' || error?.message === 'read ECONNRESET'){
                window.location.reload(); 
            }else{
                customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            }
        }
    }
);

//appointment-destroy
export const appointment_destroy = createAsyncThunk(
    "auth/appointment-destroy",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/appointment-destroy?appointment_id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//appointment-export
export const appointment_export = createAsyncThunk(
    "auth/appointment-export",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/appointment-export`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// get-existing-doctor-address
export const get_existing_doctor_address = createAsyncThunk(
    "auth/get-existing-doctor-address",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/get-existing-doctor-address?appointment_with=${SearchData.id}&search_address=${SearchData.search_address}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);

// appointment-search-location
export const get_appointment_search_location = createAsyncThunk(
    "auth/appointment-search-location",
    async (Search, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/appointment-search-location?search_location=${Search}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);