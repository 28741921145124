import React from 'react';

export const DashboardSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
            fill="#D0D5DD" />
        <path fillRule="evenodd" clipRule="evenodd"
            d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
            fill="#FF692E" />
    </svg>
);
export const MemberSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9968 15.1748C7.68376 15.1748 3.99976 15.8548 3.99976 18.5748C3.99976 21.2958 7.66076 21.9998 11.9968 21.9998C16.3098 21.9998 19.9938 21.3208 19.9938 18.5998C19.9938 15.8788 16.3338 15.1748 11.9968 15.1748Z" fill="#FF692E"/>
<path d="M11.9968 12.584C14.9348 12.584 17.2888 10.229 17.2888 7.292C17.2888 4.355 14.9348 2 11.9968 2C9.05983 2 6.70483 4.355 6.70483 7.292C6.70483 10.229 9.05983 12.584 11.9968 12.584Z" fill="#D0D5DD"/>
</svg>
)
export const AppointmentsSvg = () => (
    <svg width="24" height="24" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 14.8698V7.25684H18V14.9308C18 18.0698 16.0241 19.9998 12.8628 19.9998H5.12733C1.99561 19.9998 0 18.0298 0 14.8698ZM4.95938 12.4098C4.50494 12.4308 4.12953 12.0698 4.10977 11.6108C4.10977 11.1508 4.46542 10.7708 4.91987 10.7498C5.36443 10.7498 5.72997 11.1008 5.73985 11.5498C5.7596 12.0108 5.40395 12.3908 4.95938 12.4098ZM9.01976 12.4098C8.56531 12.4308 8.1899 12.0698 8.17014 11.6108C8.17014 11.1508 8.5258 10.7708 8.98024 10.7498C9.42481 10.7498 9.79034 11.1008 9.80022 11.5498C9.81998 12.0108 9.46432 12.3908 9.01976 12.4098ZM13.0505 16.0898C12.596 16.0798 12.2305 15.6998 12.2305 15.2398C12.2206 14.7798 12.5862 14.4008 13.0406 14.3908H13.0505C13.5148 14.3908 13.8902 14.7708 13.8902 15.2398C13.8902 15.7098 13.5148 16.0898 13.0505 16.0898ZM8.17014 15.2398C8.1899 15.6998 8.56531 16.0608 9.01976 16.0398C9.46432 16.0208 9.81998 15.6408 9.80022 15.1808C9.79034 14.7308 9.42481 14.3798 8.98024 14.3798C8.5258 14.4008 8.17014 14.7798 8.17014 15.2398ZM4.09989 15.2398C4.11965 15.6998 4.49506 16.0608 4.94951 16.0398C5.39407 16.0208 5.74973 15.6408 5.72997 15.1808C5.72009 14.7308 5.35456 14.3798 4.90999 14.3798C4.45554 14.4008 4.09989 14.7798 4.09989 15.2398ZM12.2404 11.6008C12.2404 11.1408 12.596 10.7708 13.0505 10.7608C13.4951 10.7608 13.8507 11.1198 13.8705 11.5608C13.8804 12.0208 13.5247 12.4008 13.0801 12.4098C12.6257 12.4198 12.2503 12.0698 12.2404 11.6108V11.6008Z" fill="#FF692E"/>
<path d="M0.00341797 7.25723C0.016261 6.67023 0.0656573 5.50523 0.158522 5.13023C0.632726 3.02123 2.24304 1.68123 4.54491 1.49023H13.456C15.7381 1.69123 17.3682 3.04023 17.8424 5.13023C17.9343 5.49523 17.9837 6.66923 17.9965 7.25723H0.00341797Z" fill="#D0D5DD"/>
<path d="M5.30489 4.59C5.73958 4.59 6.06559 4.261 6.06559 3.82V0.771C6.06559 0.33 5.73958 0 5.30489 0C4.8702 0 4.54419 0.33 4.54419 0.771V3.82C4.54419 4.261 4.8702 4.59 5.30489 4.59Z" fill="#FF692E"/>
<path d="M12.695 4.59C13.1198 4.59 13.4557 4.261 13.4557 3.82V0.771C13.4557 0.33 13.1198 0 12.695 0C12.2603 0 11.9343 0.33 11.9343 0.771V3.82C11.9343 4.261 12.2603 4.59 12.695 4.59Z" fill="#FF692E"/>
</svg>
)
export const CommunicationSvg = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4274 2.5783C20.9274 2.0673 20.1874 1.8783 19.4974 2.0783L3.40742 6.7273C2.67942 6.9293 2.16342 7.5063 2.02442 8.2383C1.88242 8.9843 2.37842 9.9323 3.02642 10.3283L8.05742 13.4003C8.57342 13.7163 9.23942 13.6373 9.66642 13.2093L15.4274 7.4483C15.7174 7.1473 16.1974 7.1473 16.4874 7.4483C16.7774 7.7373 16.7774 8.2083 16.4874 8.5083L10.7164 14.2693C10.2884 14.6973 10.2084 15.3613 10.5234 15.8783L13.5974 20.9283C13.9574 21.5273 14.5774 21.8683 15.2574 21.8683C15.3374 21.8683 15.4274 21.8683 15.5074 21.8573C16.2874 21.7583 16.9074 21.2273 17.1374 20.4773L21.9074 4.5083C22.1174 3.8283 21.9274 3.0883 21.4274 2.5783Z" fill="#FF692E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.01049 16.8074C2.81849 16.8074 2.62649 16.7344 2.48049 16.5874C2.18749 16.2944 2.18749 15.8204 2.48049 15.5274L3.84549 14.1614C4.13849 13.8694 4.61349 13.8694 4.90649 14.1614C5.19849 14.4544 5.19849 14.9294 4.90649 15.2224L3.54049 16.5874C3.39449 16.7344 3.20249 16.8074 3.01049 16.8074ZM6.77169 17.9998C6.57969 17.9998 6.38769 17.9268 6.24169 17.7798C5.94869 17.4868 5.94869 17.0128 6.24169 16.7198L7.60669 15.3538C7.89969 15.0618 8.37469 15.0618 8.66769 15.3538C8.95969 15.6468 8.95969 16.1218 8.66769 16.4148L7.30169 17.7798C7.15569 17.9268 6.96369 17.9998 6.77169 17.9998ZM7.02539 21.5678C7.17139 21.7148 7.36339 21.7878 7.55539 21.7878C7.74739 21.7878 7.93939 21.7148 8.08539 21.5678L9.45139 20.2028C9.74339 19.9098 9.74339 19.4348 9.45139 19.1418C9.15839 18.8498 8.68339 18.8498 8.39039 19.1418L7.02539 20.5078C6.73239 20.8008 6.73239 21.2748 7.02539 21.5678Z" fill="#D0D5DD"/>
</svg>
)

export const MarSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_14885_17387)">
<path d="M13.8862 21.6491C16.0199 23.7829 19.513 23.7829 21.6487 21.6491C23.7824 19.5154 23.7824 16.0223 21.6487 13.8866L15.8812 8.11914L8.11865 15.8816L13.8862 21.6491Z" fill="#FF692E"/>
<path d="M15.8813 8.11879L10.1138 2.35129C7.98004 0.217539 4.48691 0.217539 2.35129 2.35129C0.217539 4.48504 0.217539 7.97816 2.35129 10.1138L8.11879 15.8813L15.8813 8.11879Z" fill="#D0D5DD"/>
</g>
<defs>
<clipPath id="clip0_14885_17387">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
)
export const NotificationsSvg = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.7695 11.6453C19.039 10.7923 18.7071 10.0531 18.7071 8.79716V8.37013C18.7071 6.73354 18.3304 5.67907 17.5115 4.62459C16.2493 2.98699 14.1244 2 12.0442 2H11.9558C9.91935 2 7.86106 2.94167 6.577 4.5128C5.71333 5.58842 5.29293 6.68822 5.29293 8.37013V8.79716C5.29293 10.0531 4.98284 10.7923 4.23049 11.6453C3.67691 12.2738 3.5 13.0815 3.5 13.9557C3.5 14.8309 3.78723 15.6598 4.36367 16.3336C5.11602 17.1413 6.17846 17.6569 7.26375 17.7466C8.83505 17.9258 10.4063 17.9933 12.0005 17.9933C13.5937 17.9933 15.165 17.8805 16.7372 17.7466C17.8215 17.6569 18.884 17.1413 19.6363 16.3336C20.2118 15.6598 20.5 14.8309 20.5 13.9557C20.5 13.0815 20.3231 12.2738 19.7695 11.6453Z" fill="#FF692E"/>
<path d="M14.0088 19.2285C13.5088 19.1217 10.4627 19.1217 9.96275 19.2285C9.53539 19.3272 9.07324 19.5568 9.07324 20.0604C9.09809 20.5408 9.37935 20.9648 9.76895 21.2337L9.76795 21.2347C10.2718 21.6275 10.8632 21.8773 11.4824 21.9669C11.8123 22.0122 12.1482 22.0102 12.4901 21.9669C13.1083 21.8773 13.6997 21.6275 14.2036 21.2347L14.2026 21.2337C14.5922 20.9648 14.8734 20.5408 14.8983 20.0604C14.8983 19.5568 14.4361 19.3272 14.0088 19.2285Z" fill="#D0D5DD"/>
</svg>

);
export const TasksSvg = () => (
<svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.3402 0H5.67024C2.28024 0 0.000244141 2.38 0.000244141 5.92V14.09C0.000244141 17.62 2.28024 20 5.67024 20H14.3402C17.7302 20 20.0002 17.62 20.0002 14.09V5.92C20.0002 2.38 17.7302 0 14.3402 0Z" fill="#FF692E"/>
<path d="M8.81344 13.2485C8.58944 13.2485 8.36544 13.1635 8.19444 12.9925L5.82144 10.6195C5.47944 10.2775 5.47944 9.72345 5.82144 9.38245C6.16344 9.04045 6.71644 9.03945 7.05844 9.38145L8.81344 11.1365L12.9414 7.00845C13.2834 6.66645 13.8364 6.66645 14.1784 7.00845C14.5204 7.35045 14.5204 7.90445 14.1784 8.24645L9.43244 12.9925C9.26144 13.1635 9.03744 13.2485 8.81344 13.2485Z" fill="#D0D5DD"/>
</svg>

);

export const AdminSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3345 2H7.66549C4.27649 2 2.00049 4.378 2.00049 7.917V16.084C2.00049 19.622 4.27649 22 7.66549 22H16.3335C19.7225 22 22.0005 19.622 22.0005 16.084V7.917C22.0005 4.378 19.7235 2 16.3345 2Z" fill="#FF692E" />
    <path opacity="0.7" fill-rule="evenodd" clip-rule="evenodd" d="M11.3144 11.2484H17.0144C17.4244 11.2484 17.7644 11.5884 17.7644 11.9984V13.8484C17.7644 14.2684 17.4244 14.5984 17.0144 14.5984C16.5944 14.5984 16.2644 14.2684 16.2644 13.8484V12.7484H14.9344V13.8484C14.9344 14.2684 14.5944 14.5984 14.1844 14.5984C13.7644 14.5984 13.4344 14.2684 13.4344 13.8484V12.7484H11.3144C10.9944 13.8184 10.0144 14.5984 8.84437 14.5984C7.40437 14.5984 6.23438 13.4384 6.23438 11.9984C6.23438 10.5684 7.40437 9.39844 8.84437 9.39844C10.0144 9.39844 10.9944 10.1784 11.3144 11.2484ZM7.73438 11.9984C7.73438 12.6084 8.23438 13.0984 8.84438 13.0984C9.44438 13.0984 9.94438 12.6084 9.94438 11.9984C9.94438 11.3884 9.44438 10.8984 8.84438 10.8984C8.23438 10.8984 7.73438 11.3884 7.73438 11.9984Z" fill="#D0D5DD" />
</svg>
);
export const HomesSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.14373 20.7821V17.7152C9.14372 16.9381 9.77567 16.3067 10.5584 16.3018H13.4326C14.2189 16.3018 14.8563 16.9346 14.8563 17.7152V20.7732C14.8562 21.4473 15.404 21.9951 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0007C21.1356 20.3586 21.5 19.4868 21.5 18.5775V9.86585C21.5 9.13139 21.1721 8.43471 20.6046 7.9635L13.943 2.67427C12.7785 1.74912 11.1154 1.77901 9.98539 2.74538L3.46701 7.9635C2.87274 8.42082 2.51755 9.11956 2.5 9.86585V18.5686C2.5 20.4637 4.04738 22 5.95617 22H7.87229C8.19917 22.0023 8.51349 21.8751 8.74547 21.6464C8.97746 21.4178 9.10793 21.1067 9.10792 20.7821H9.14373Z" fill="#FF692E"/>
    </svg>
    
);
export const UsersSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9488 14.5399C8.49884 14.5399 5.58789 15.1037 5.58789 17.2794C5.58789 19.4561 8.51765 20 11.9488 20C15.3988 20 18.3098 19.4362 18.3098 17.2605C18.3098 15.0839 15.38 14.5399 11.9488 14.5399Z" fill="#FF692E"/>
<path d="M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z" fill="#D0D5DD"/>
<path d="M21.0881 9.21929C21.6925 6.84182 19.9205 4.7066 17.664 4.7066C17.4187 4.7066 17.1841 4.73362 16.9549 4.77955C16.9244 4.78675 16.8904 4.80206 16.8725 4.82908C16.8519 4.8633 16.8671 4.90923 16.8895 4.93895C17.5673 5.89534 17.9568 7.05976 17.9568 8.30973C17.9568 9.50747 17.5996 10.6242 16.9728 11.5508C16.9083 11.6463 16.9656 11.7751 17.0793 11.7949C17.2369 11.8228 17.3981 11.8372 17.5629 11.8417C19.2059 11.8849 20.6807 10.8214 21.0881 9.21929Z" fill="#D0D5DD"/>
<path d="M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z" fill="#FF692E"/>
<path d="M7.04459 4.77979C6.81626 4.73296 6.58077 4.70685 6.33543 4.70685C4.07901 4.70685 2.30701 6.84207 2.9123 9.21953C3.31882 10.8216 4.79355 11.8852 6.43661 11.842C6.60136 11.8375 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.5511C6.3999 10.6235 6.04263 9.50771 6.04263 8.30997C6.04263 7.0591 6.43303 5.89468 7.11085 4.93919C7.13234 4.90947 7.14845 4.86354 7.12696 4.82932C7.10906 4.80141 7.07593 4.787 7.04459 4.77979Z" fill="#D0D5DD"/>
<path d="M3.32156 13.5126C2.21752 13.7297 1.49225 14.1718 1.19139 14.8166C0.936203 15.3453 0.936203 15.9585 1.19139 16.4872C1.65163 17.485 3.13531 17.8065 3.71195 17.8884C3.83104 17.9065 3.92595 17.8038 3.91342 17.6831C3.61883 14.9166 5.9621 13.6045 6.56918 13.3028C6.59425 13.2884 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2046 3.84358 13.3848 3.32156 13.5126Z" fill="#FF692E"/>
</svg>


);
export const RolesAndPermissionsSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0833 15.9579H3.50777C2.67555 15.9579 2 16.6217 2 17.4393C2 18.2558 2.67555 18.9206 3.50777 18.9206H10.0833C10.9155 18.9206 11.5911 18.2558 11.5911 17.4393C11.5911 16.6217 10.9155 15.9579 10.0833 15.9579Z" fill="#D0D5DD" />
    <path d="M22.0001 6.37855C22.0001 5.56202 21.3246 4.89832 20.4934 4.89832H13.9179C13.0857 4.89832 12.4102 5.56202 12.4102 6.37855C12.4102 7.19617 13.0857 7.85988 13.9179 7.85988H20.4934C21.3246 7.85988 22.0001 7.19617 22.0001 6.37855Z" fill="#D0D5DD" />
    <path d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z" fill="#FF692E" />
    <path d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7778 18.5609 20.7778C16.6621 20.7778 15.1221 19.2648 15.1221 17.3992C15.1221 15.5326 16.6621 14.0196 18.5609 14.0196C20.4609 14.0196 21.9998 15.5326 21.9998 17.3992Z" fill="#FF692E" />
</svg>
);

export const SettingsSvg = () => (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.2301 12.37C19.036 12.07 18.76 11.77 18.4023 11.58C18.1162 11.44 17.9322 11.21 17.7687 10.94C17.2475 10.08 17.5541 8.95 18.4228 8.44C19.4447 7.87 19.7718 6.6 19.179 5.61L18.4943 4.43C17.9118 3.44 16.6344 3.09 15.6226 3.67C14.7233 4.15 13.5685 3.83 13.0473 2.98C12.8838 2.7 12.7918 2.4 12.8122 2.1C12.8429 1.71 12.7203 1.34 12.5363 1.04C12.1582 0.42 11.4735 0 10.7172 0H9.27627C8.53024 0.02 7.84553 0.42 7.4674 1.04C7.27323 1.34 7.16081 1.71 7.18125 2.1C7.20169 2.4 7.10972 2.7 6.9462 2.98C6.425 3.83 5.27019 4.15 4.38109 3.67C3.35913 3.09 2.09191 3.44 1.49917 4.43L0.814459 5.61C0.231943 6.6 0.55897 7.87 1.57071 8.44C2.43937 8.95 2.74596 10.08 2.23498 10.94C2.06125 11.21 1.87729 11.44 1.59115 11.58C1.24368 11.77 0.937094 12.07 0.773581 12.37C0.395456 12.99 0.415896 13.77 0.79402 14.42L1.49917 15.62C1.87729 16.26 2.58245 16.66 3.31825 16.66C3.66572 16.66 4.0745 16.56 4.40153 16.36C4.65702 16.19 4.96361 16.13 5.30085 16.13C6.31259 16.13 7.16081 16.96 7.18125 17.95C7.18125 19.1 8.12145 20 9.30692 20H10.6968C11.872 20 12.8122 19.1 12.8122 17.95C12.8429 16.96 13.6911 16.13 14.7029 16.13C15.0299 16.13 15.3365 16.19 15.6022 16.36C15.9292 16.56 16.3278 16.66 16.6855 16.66C17.411 16.66 18.1162 16.26 18.4943 15.62L19.2097 14.42C19.5776 13.75 19.6083 12.99 19.2301 12.37Z" fill="#FF692E"/>
    <path d="M10.0117 12.8297C8.40726 12.8297 7.10938 11.5797 7.10938 10.0097C7.10938 8.43969 8.40726 7.17969 10.0117 7.17969C11.6162 7.17969 12.8834 8.43969 12.8834 10.0097C12.8834 11.5797 11.6162 12.8297 10.0117 12.8297Z" fill="#D0D5DD"/>
    </svg>
    
);
export const AuditSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 2.25024C5.43489 2.25024 3.10889 4.80224 3.10889 12.0012C3.10889 19.1992 5.43489 21.7512 11.9999 21.7512C18.5639 21.7512 20.8909 19.1992 20.8909 12.0012C20.8909 4.80224 18.5639 2.25024 11.9999 2.25024Z" fill="#FF692E"/>
    <path d="M8.375 12.688H15.595C16.009 12.688 16.345 12.352 16.345 11.938C16.345 11.524 16.009 11.188 15.595 11.188H8.375C7.961 11.188 7.625 11.524 7.625 11.938C7.625 12.352 7.961 12.688 8.375 12.688Z" fill="#D0D5DD"/>
    <path d="M8.375 16.447H15.595C16.009 16.447 16.345 16.111 16.345 15.697C16.345 15.283 16.009 14.947 15.595 14.947H8.375C7.961 14.947 7.625 15.283 7.625 15.697C7.625 16.111 7.961 16.447 8.375 16.447Z" fill="#D0D5DD"/>
    <path d="M11.13 7.427H8.375C7.961 7.427 7.625 7.763 7.625 8.177C7.625 8.591 7.961 8.927 8.375 8.927H11.13C11.544 8.927 11.88 8.591 11.88 8.177C11.88 7.763 11.544 7.427 11.13 7.427Z" fill="#D0D5DD"/>
    </svg>
    
);
export const ReportingSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.132 12.9724C13.723 13.0184 12.128 13.0684 11.438 12.3784C10.691 11.6304 10.633 9.98136 10.586 8.65536C10.542 7.40336 10.507 6.41336 9.99897 5.90636C9.40597 5.31336 8.41497 5.24336 7.28097 5.71136C4.92397 6.68836 2.41797 9.73336 2.41797 13.4514C2.41797 17.9724 6.09697 21.6514 10.619 21.6514C15.04 21.6514 17.613 18.2854 18.437 16.2974C18.956 15.0454 18.964 14.0374 18.459 13.5344C17.812 12.8864 16.568 12.9284 15.132 12.9724Z" fill="#FF692E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.5187 7.74265C21.0817 5.65665 18.4197 3.09965 16.0397 2.48065C14.8727 2.17565 13.8887 2.40365 13.2687 3.11765C12.4077 4.10965 12.1227 9.55965 13.1267 10.5637C13.5367 10.9737 14.5717 11.1477 15.7637 11.1477C17.6537 11.1477 19.9357 10.7097 20.7447 10.0737C21.4437 9.52365 21.7187 8.69565 21.5187 7.74265Z" fill="#D0D5DD"/>
</svg>

    
);
