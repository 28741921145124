import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'
import { DateRange } from "react-date-range";
import { format } from "date-fns";


const NonPaidServices = ({ onHandleBackStep, onSubmit }) => {
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.servicesAuthorizedNonPaid);

  const [isOpen, setIsOpen] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [selectedIndex, setSelectedIndex] = useState(null);
  // Initialize the form with useForm hook
  const { control, handleSubmit, register, getValues, setValue, formState: { errors } } = useForm({
    defaultValues: {
      alTCSServices: [{ service_provider: "", service_frequency_prior_assessment: "", service_frequency_current: "", service_change: "", start_date: "", end_date: "", is_member_hcdm_aggree: "" }],
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'alTCSServices'
  });


  useEffect(() => {
    setValue('document_comm_resource_discussed', allDddPcsp?.document_comm_resource_discussed);
    setValue('nonpaid_support_description', allDddPcsp?.nonpaid_support_description);

    const lengthDifference = allDddPcsp?.alTCSServices?.length - fields.length;
    if (lengthDifference > 0) {
      for (let i = 0; i < lengthDifference; i++) {
        append({});
      }
    }
    allDddPcsp?.alTCSServices.forEach((item, index) => {
      setValue(`alTCSServices.${index}.service_provider`, item.service_provider || '');
      setValue(`alTCSServices.${index}.service_frequency_prior_assessment`, item.service_frequency_prior_assessment || '');
      setValue(`alTCSServices.${index}.service_frequency_current`, item.service_frequency_current || '');
      setValue(`alTCSServices.${index}.service_change`, item.service_change || '');
      setValue(`alTCSServices.${index}.start_date`, item.start_date || '');
      setValue(`alTCSServices.${index}.end_date`, item.end_date || '');
      setValue(`alTCSServices.${index}.is_member_hcdm_aggree`, item.is_member_hcdm_aggree || '');
    });
  }, [allDddPcsp])

  const handleSelect = (ranges) => {    
    if (selectedIndex !== null) {
      const { startDate, endDate } = ranges.selection;
      setValue(`alTCSServices.${selectedIndex}.start_date`, startDate);
      setValue(`alTCSServices.${selectedIndex}.end_date`, endDate);
      setSelectionRange({
        startDate,
        endDate,
        key: "selection",
      });
    }
  };

  const toggleCalendar = (index) => {
    setSelectedIndex(index);
    setIsOpen(index);
  };
  // Helper function to format dates safely
  const safeFormatDate = (date) => {    
    try {
      return date ? format(new Date(date), "MMM d, yyyy") : 'Select date';
    } catch (e) {
      return "N/A";
    }
  };
  
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-title font-24">Non-paid services/support</div>
            <div className="hd-subtitle font-16">Documentation shall reflect the unpaid supports that will assist the
              member to achieve goals, and the provider of those services and supports including natural supports.
              Natural supports are unpaid supports that are provided voluntarily to the individual in lieu of ALTCS
              HCBS paid services. Informal/natural supports must be indicated on the HNT, as applicable.</div>
          </div>

        </div>
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-16">Are people assisting you who are not paid to do so? Are you satisfied
              with how they are helping you? Do you feel these supports help you to be able to do more? Go out
              places? Are you currently utilizing community resources? What support do you need from a natural
              support to help you accomplish your personal goals?</div>
          </div>

        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">List out non-paid “natural supports” involved in member’s life*</label>
          <textarea className="form-control" placeholder="" {...register("nonpaid_support_description", { required: "true" })} maxLength={150} />
          {errors.nonpaid_support_description && errors.nonpaid_support_description.type === "required" && (<span className="error">This is required!</span>)}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Document community resources discussed*</label>
          <input
            type="input"
            className="form-control"
            placeholder="Enter Document community resources discussed"
            {...register(`document_comm_resource_discussed`, { required: "This is required!" })}
            maxLength={20}
          />
          {errors?.document_comm_resource_discussed && (
            <span className="error">{errors.document_comm_resource_discussed.message}</span>
          )}
        </div>
        <div className="member_vital_basic_title">
          <h4>ALTCS services</h4>
        </div>

        {fields.map((member, index) => (
          <div key={member.id} className="row">
            {index > 0 && (
              <hr />
            )}
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Service & Prodivder*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Service & Prodivder"
                {...register(`alTCSServices.${index}.service_provider`, { required: "Service & Prodivder is required!" })}
                maxLength={20}
              />
              {errors.alTCSServices && errors.alTCSServices[index]?.service_provider && (
                <span className="error">{errors.alTCSServices[index].service_provider.message}</span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Service frequency in place prior to this assessment*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Service frequency"
                {...register(`alTCSServices.${index}.service_frequency_prior_assessment`, { required: "This is required!" })}
                maxLength={20}
              />
              {errors.alTCSServices && errors.alTCSServices[index]?.service_frequency_prior_assessment && (
                <span className="error">{errors.alTCSServices[index].service_frequency_prior_assessment.message}</span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Service frequency currently assessed*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Service frequency"
                {...register(`alTCSServices.${index}.service_frequency_current`, { required: "This is required!" })}
                maxLength={20}
              />
              {errors.alTCSServices && errors.alTCSServices[index]?.service_frequency_current && (
                <span className="error">{errors.alTCSServices[index].service_frequency_current.message}</span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Service change*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Service change"
                {...register(`alTCSServices.${index}.service_change`, { required: "Service change is required!" })}
                maxLength={20}
              />
              {errors.alTCSServices && errors.alTCSServices[index]?.service_change && (
                <span className="error">{errors.alTCSServices[index].service_change.message}</span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="" className="form-label">Start/end date*</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={`${safeFormatDate(getValues(`alTCSServices.${index}.start_date`), "MMM d, yyyy")} - ${safeFormatDate(getValues(`alTCSServices.${index}.end_date`), "MMM d, yyyy")}`}
                  onClick={() => toggleCalendar(index)}
                />
                <button
                  className="btn btn-outline-secondary calander-icon"
                  type="button"
                  onClick={() => toggleCalendar(index)}
                >
                  <i className="fa fa-calendar"></i>
                </button>
              </div>
              {isOpen === index && (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <button
                    onClick={() => toggleCalendar(null)}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "transparent",
                      border: "none",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  >
                    &#10005;
                  </button>
                  <DateRange
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label for="" className="form-label">Member/HCDM*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Member/HCDM"
                {...register(`alTCSServices.${index}.is_member_hcdm_aggree`, { required: "Member/HCDM is required!" })}
                maxLength={20}
              />
              {errors.alTCSServices && errors.alTCSServices[index]?.is_member_hcdm_aggree && (
                <span className="error">{errors.alTCSServices[index].is_member_hcdm_aggree.message}</span>
              )}
            </div>
            {index > 0 && (
              <div className="col-12 mb-3">
                <a
                  href="#"

                  className="stepone_remove"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  Remove
                </a>
              </div>
            )}
          </div>
        ))}
        <div className="stepone_health_planbtn">
          <button type="button" className="font-14" onClick={() => append({ id: Date.now() })}><svg width="12" height="12" viewBox="0 0 12 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
            Add another health plan</button>
        </div>
        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </>
  )
}

export default NonPaidServices
