import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter';

const SafetyAndSelfHelpRisks = ({ onHandleBackStep, onSubmit, StateOfRisk }) => {
    const allTypeRisk = useSelector(state => state?.AuthMember?.allTypeRisk?.safety_risk?.dddpcspRisksMaster);
    const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.risks);

    // Initialize state for the form
    const [healthMediRisks, setHealthMediRisks] = useState([{ dddpcsp_risk_master_id: "", state_of_risk: "", risk_type: "SafetyAndSelfHelpRisks" }]);
    const [allRisk, setAllRisk] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (allTypeRisk) {
            setAllRisk(allTypeRisk);
        }
    }, [allTypeRisk]);

    useEffect(() => {
        let filterd = allDddPcsp?.filter((item) => item.risk_type === 'SafetyAndSelfHelpRisks');
        if (filterd && filterd.length > 0) {
            setHealthMediRisks(filterd.map(item => ({
                dddpcsp_risk_master_id: item.dddpcsp_risk_master_id || '',
                state_of_risk: item.state_of_risk || '',
                risk_type: item.risk_type || 'SafetyAndSelfHelpRisks',
            })));
        }
    }, [allDddPcsp]);

    // Handle change for select fields
    const handleChange = (index, field, value) => {
        const updatedRisks = [...healthMediRisks];
        updatedRisks[index][field] = value;
        setHealthMediRisks(updatedRisks);
        setErrors({...errors,[ `${field}${index}`]:''})
    };

    // Handle adding a new risk
    const handleAddRisk = () => {
        setHealthMediRisks([...healthMediRisks, { dddpcsp_risk_master_id: "", state_of_risk: "", risk_type: "SafetyAndSelfHelpRisks" }]);
    };

    // Handle removing a risk
    const handleRemoveRisk = (index) => {
        const updatedRisks = healthMediRisks.filter((_, i) => i !== index);
        setHealthMediRisks(updatedRisks);
    };

    // Form validation function
    const validateForm = () => {
        const newErrors = {};
        healthMediRisks.forEach((member, index) => {
            if (!member.dddpcsp_risk_master_id) {
                newErrors[`dddpcsp_risk_master_id${index}`] = "Risk is required!";
            }
            if (!member.state_of_risk) {
                newErrors[`state_of_risk${index}`] = "State of risk is required!";
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    // Form submit handler
    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Validate form before submitting
        if (validateForm()) {            
            onSubmit({healthMediRisks: [...healthMediRisks]});
        }
    };

    return (
        <>
            <form onSubmit={handleFormSubmit} className="row">
                <div className="member_vital_basic_title">
                    <h4>Safety and self-help risks</h4>
                </div>
                {healthMediRisks.map((member, index) => (
                    <div key={index} className="row">
                        {index > 0 && (
                            <hr />
                        )}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Risk*</label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={member.dddpcsp_risk_master_id} // Bind the selected value
                                onChange={(e) => handleChange(index, 'dddpcsp_risk_master_id', e.target.value)}>
                                <option value="">Select</option>
                                {allRisk && allRisk.map((item, index) => (
                                    <option
                                        key={index}
                                        value={item.id}
                                        disabled={healthMediRisks.some(risk => risk.dddpcsp_risk_master_id === item.id)} // Disable selected option
                                    >
                                        {item.risk_name}
                                    </option>
                                ))}
                            </select>
                            {errors[`dddpcsp_risk_master_id${index}`] && (
                                <span className="error">{errors[`dddpcsp_risk_master_id${index}`]}</span>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">State of risk*</label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                value={member.state_of_risk}
                                onChange={(e) => handleChange(index, 'state_of_risk', e.target.value)}>
                                <option value="">Select</option>
                                {StateOfRisk && StateOfRisk.map((item, index) => (
                                    <option key={index} value={item.value}>{item.lable}</option>
                                ))}
                            </select>
                            {errors[`state_of_risk${index}`] && (
                                <span className="error">{errors[`state_of_risk${index}`]}</span>
                            )}
                        </div>
                        {index > 0 && (
                            <div className="col-12 mb-3">
                                <a
                                    href="#"
                                    className="stepone_remove"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveRisk(index);
                                    }}
                                >
                                    Remove
                                </a>
                            </div>
                        )}
                    </div>
                ))}
                <div className="stepone_health_planbtn">
                    <button type="button" className="font-14" onClick={handleAddRisk}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        Add another health plan
                    </button>
                </div>
                <PcspFooter onHandleBackStep={onHandleBackStep} />
            </form>
        </>
    );
};

export default SafetyAndSelfHelpRisks;
