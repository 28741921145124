import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNotification } from '../redux/features/commonSlice'
import Ellipse from '../Images/Ellipse1.png'
import { NotificationTimeFormate } from './Common/Common'
import { getNotificationList, readMsgUpdate } from '../redux/services/AuthNotification'
import { MemberSvg, HomeSvg, AppointmentSvg, TaskSvg, RoleSvg, CommunicationSvg, MARSvg } from '../Images/NotificationSvg';


const images = {
	Homes: <HomeSvg />,
	Member: <MemberSvg />,
	Communication: <CommunicationSvg/>,
	Appointments: <AppointmentSvg />,
	role: <RoleSvg />,
	Tasks: <TaskSvg />,
	MAR: <MARSvg/>,
	Users: <MemberSvg />
};

const NotificationModal = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const NotificationList = useSelector(state => state?.AuthNotification?.NotificationList);

	const [viewType, setViewType] = useState('all');

	const handleSeeAll = () => {
		dispatch(setNotification(false))
		navigate('/auth/notification');
	}


	// Filter notifications based on 'all' or 'unread'
	const filteredNotifications = NotificationList && NotificationList.filter(Notification =>
		viewType === 'all' || (viewType === 'unread' && !Notification?.notificationAccess[0]?.msg_seen)
	);

	// Handle view type change (all / unread)
	const handleViewType = (type) => {
		setViewType(type);
	};

	// Handle click to mark notification as read
	const markAsRead = async (id) => {
		let res = await dispatch(readMsgUpdate(id))
		if (res.payload.status) {
			dispatch(getNotificationList({module_id: ''}))
		}
	};

	return (
		<div className="modal fade new_medi_modal show" >
			<div className="modal-dialog notification_popup_align">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">{NotificationList && NotificationList.length > 2 ? 'Notifications' : 'Notification'}</h5>
						<button type="button" className="btn-close" onClick={() => dispatch(setNotification(false))}></button>
					</div>
					<div className="modal-body">
						<div className="row">
								<div className="col-md-12 col-12">
									<nav>
										<div className="nav nav-tabs" id="nav-tab" role="tablist">
											<button
												className={`nav-link ${viewType === 'all' ? 'active' : ''}`}
												onClick={() => handleViewType('all')}
											>
												All
											</button>
											<button
												className={`nav-link ${viewType === 'unread' ? 'active' : ''}`}
												onClick={() => handleViewType('unread')}
											>
												Unread <span className="unread_msg">{NotificationList && NotificationList.filter(n => !n?.notificationAccess[0]?.msg_seen).length}</span>
											</button>
										</div>
									</nav>

									<div className="tab-content tabs_mem" id="nav-tabContent">
										<div className="tab-pane fade show active">
										{filteredNotifications && filteredNotifications.length > 0 ?
											<div className="notifivation_member">
											{filteredNotifications.map(notification => {															
												return (
													<li className={`${notification?.notificationAccess[0]?.msg_seen ? '' : 'unread_mag_bc'}`} key={notification?.id} onClick={() => markAsRead(notification?.notificationAccess[0]?.id)}>
														<div className="notifivation_member_profile">
															{images[notification?.module ? notification?.module?.module_name : 'role']}
														</div>
														<div className="notifivation_member_contant">
															<div className="font-14 hd-subtitle">
																{notification?.module === null ? 'Role' : notification?.module?.module_name} <div className="gray_color"></div>{NotificationTimeFormate(notification?.created_date)}
															</div>
															<p className="font-18 f_weight">{notification?.message}</p>
															{notification?.description && <p>{notification.description.length > 50
																? notification.description.substring(0, 50) + '...'
																: notification.description}</p>}
														</div>
													</li>
												)
											})}
										</div>
										:
											<h5>No Notification</h5>
}
										</div>
									</div>
								</div>
						</div>
					</div>
					<div className="modal-all_notification border-0">

						<p className="text=-center"> <a onClick={() => handleSeeAll()}>See all notifications <svg width="19" height="14"
							viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.5 7H17.5M17.5 7L11.5 1M17.5 7L11.5 13" stroke="black" stroke-width="2"
								stroke-linecap="round" stroke-linejoin="round" />
						</svg>
						</a></p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotificationModal