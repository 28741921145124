import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const SummaryOfConversation = ({onHandleBackStep, onSubmit, isGoBack}) => {
  
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.preferenceStrenth);
  const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    if (UploadedPdfData?.length > 0 && !isGoBack) {
      UploadedPdfData.forEach((item) => {
        switch (item.key) {
          case 'SUMMARY OF CONVERSATION:':
            setValue('conversation_summary', item?.value);
            break
        }
      })
    }else {
      // Meeting notes
      setValue('conversation_summary', allDddPcsp?.conversation_summary);
    }

  }, [allDddPcsp, UploadedPdfData])

    return(
        <>
        <div className="alert alert-light sap-alert-heading">
          <div className="hd-subtitle font-16">Document brief background of the member’s life experiences (e.g. place of
            birth, developmental, education, and employment history, justice system involvement, previous living
            situations):</div>
          <div className="hd-title stepthree_pref_head">
            PS: FOR INDIVIDUALS WHO ARE UNABLE TO EXPRESS THEIR PREFERENCES, THE QUESTIONS ABOUT THE FOLLOWING MAY BE
            ASKED OF FAMILY MEMBERS, FRIENDS, OR OTHERS THAT KNOW THE MEMBER TO HELP INFORM PERSONAL GOAL DEVELOPMENT
            AND/OR MEANINGFUL DAY ACTIVITIES.
          </div>
        </div>
        <div className="member_vitalstep_one_form">
          <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-12">
              <label for="" className="form-label">Summary of conversation*</label>
              <div className="col-12 mb-3">
                        <textarea className="form-control" placeholder="Enter Summary of conversation" {...register("conversation_summary", { required: "true" })} maxLength={150} />
                        {errors.conversation_summary && errors.conversation_summary.type === "required" && (<span className="error">Summary of conversation is required!</span>)}

                    </div>
            </div>
            <PcspFooter onHandleBackStep={onHandleBackStep} />
          </form>
          </div>
        </>
    )
}
export default SummaryOfConversation