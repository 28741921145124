import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import './dashboard.css';
import './App.css';
import { AccountCreation, Login, VerifyPhone, ForgetPassword, ResetPassword, Home, Subscription, SubscriptionPage, Setting, Verify_text_otp_Number, Dashboard, AllUsers, AddUser, EditUser, PrivacyPolicy, TermsAndServices, AddHome, AllHome, EditHome, CancelSubscription, AllMembers, AddMember, EditMember, DddPcsp, MAR, EditMar, OpenMar, Communication, CommunicationDetails, Appointment, Documents, Tasks, Notification, PageNotFound } from "./pages/Index";
import MainLayout from "./layouts/Owner/MainLayout";
import AuthLayout from "./layouts/authOwnerLayout/AuthLayout";
import { AfterLogin, Protected_routes, Required_auth } from "./utils/Utils";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import IndividualizedHealth from "./pages/auth/Members/IndividualizedHealth";
import MedicalHistory from "./pages/auth/Members/MedicalHistory";
import GeneralConsentAndAuthorization from "./pages/auth/Members/GeneralConsentAndAuthorization";
import BehaviorModifyingMedicationConsent from "./pages/auth/Members/BehaviorModifyingMedicationConsent";
import Loader from './components/Common/Loader';
import { get_task_board_view } from './redux/services/AuthTasks'
import {getNotificationList} from './redux/services/AuthNotification'
import NotificationModal from './components/Notification'
import TaskNotification from './components/TaskNotification'
import { setTaskNotification, setNotification } from './redux/features/commonSlice'
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme css file
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { AuthHomes, AuthMember, AuthSettings, AuthUsers, common, auth, AuthMar, AuthCommunication, AuthAppointment, AuthDocuments, AuthTasks, suscription, AuthNotification, owner, AuthDashboard } = useSelector(state => state || {});
  const currentUserRole = useSelector(state => state?.auth?.currentUserRole);
  const taskNotification = useSelector(state => state?.common?.taskNotification);
  const notification = useSelector(state => state?.common?.notification);

  const isLoading = AuthHomes?.isLoading || AuthMember?.isLoading || AuthSettings?.isLoading || AuthUsers?.isLoading || common?.isLoading || auth?.isLoading || AuthMar?.isLoading || AuthCommunication?.isLoading || AuthAppointment?.isLoading || AuthDocuments?.isLoading || AuthTasks?.isLoading || suscription?.isLoading || AuthNotification?.isLoading || owner?.isLoading || AuthDashboard?.isLoading

  useEffect(() => {
    dispatch(setTaskNotification(false))
    dispatch(setNotification(false))
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    let token =  localStorage.getItem('token')
    if(token){
      let payload = {
        page: 1,
        listData: 10,
        searchBar: '',
        role: currentUserRole,
        task_status: '',
        assigned_to: '',
        due_date: ''
      }
      dispatch(get_task_board_view(payload))
      dispatch(getNotificationList({module_id: ''}))
    }
  }, [currentUserRole])
    console.log(AuthHomes?.isLoading , AuthMember?.isLoading , AuthSettings?.isLoading , AuthUsers?.isLoading , common?.isLoading , auth?.isLoading , AuthMar?.isLoading , AuthCommunication?.isLoading , AuthAppointment?.isLoading , AuthDocuments?.isLoading , AuthTasks?.isLoading , suscription?.isLoading , AuthNotification?.isLoading , owner?.isLoading , AuthDashboard?.isLoading,'isLoadingisLoadingisLoadingisLoadingisLoading===>>>');
    
  return (
    <>
      {isLoading && <Loader />}
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/verify-phone" element={<VerifyPhone />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/reset-password-with-text" element={<Verify_text_otp_Number />} />
          <Route path="/setting/subscription" element={<Subscription />} />
          <Route path="/setting/subscription-page" element={<SubscriptionPage />} />
          <Route path="/login" element={<AfterLogin><Login /></AfterLogin>} />
          <Route path="/" element={<Home />} />
          <Route path="/account-creation" element={<Protected_routes><AccountCreation /></Protected_routes>} />
          <Route path="/term-services" element={<TermsAndServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cancel-subscription" element={<CancelSubscription />} />
        </Route>
        <Route element={<Required_auth />}>
          <Route element={<Protected_routes><AuthLayout /></Protected_routes>}>
            <Route path="/auth/settings" element={<Setting />} />
            <Route path="/auth/dashboard" element={<Dashboard />} />
            {/* Users Module */}
            <Route path="/auth/users" element={<AllUsers />} />
            <Route path="/auth/users-add" element={<AddUser />} />
            <Route path="/auth/users-edit/:id" element={<EditUser />} />
            {/* home module */}
            <Route path="/auth/homes" element={<AllHome />} />
            <Route path="/auth/add-home" element={<AddHome />} />
            <Route path="/auth/edit-home/:id" element={<EditHome />} />
            {/* member module */}
            <Route path="/auth/members" element={<AllMembers />} />
            <Route path="/auth/add-member" element={<AddMember />} />
            <Route path="/auth/edit-member/:id" element={<EditMember />} />
            <Route path="/auth/edit-members/:id" element={<AddMember />} />
            <Route path="/auth/edit-members/ddd/pcsp/:id" element={<DddPcsp />} />
            <Route path="/auth/add-member/:id" element={<AddMember />} />
            <Route path="/auth/edit-members/individualized/:id" element={<IndividualizedHealth />} />
            <Route path="/auth/edit-members/medicalhistory/:id" element={<MedicalHistory />} />
            <Route path="/auth/edit-members/general-consent/:id" element={<GeneralConsentAndAuthorization />} />
            <Route path="/auth/edit-members/behavior-modifying/:id" element={<BehaviorModifyingMedicationConsent />} />

            {/* MAR */}
            <Route path="/auth/mar" element={<MAR />} />
            <Route path="/auth/edit-mar/:id" element={<EditMar />} />
            <Route path="/auth/open-mar/:id" element={<OpenMar />} />
            {/* <Route path="/auth/add-mar" element={<AddMar />} /> */}

            {/* Appointment */}
            <Route path="/auth/appointment" element={<Appointment />} />

            {/* Communication */}
            <Route path="/auth/communication" element={<Communication />} />
            <Route path="/auth/communication-details/:id" element={<CommunicationDetails />} />

            {/* Documents */}
            <Route path="/auth/documents" element={<Documents />} />


            <Route path="/auth/tasks" element={<Tasks />} />
            <Route path="/auth/tasks/:id" element={<Tasks />} />
            <Route path="/auth/notification" element={<Notification />} />

          </Route>
        </Route>
        <Route path="*" element={<PageNotFound/>} />
      </Routes>
      {notification && <NotificationModal />}
      {taskNotification && <TaskNotification />}
    </>
  );
}

export default App;
