import React, { useEffect, useState } from 'react'
import defaultImg from '../../Images/defaultImg.png';
import logoDark from '../../Images/logo-dark.png';
import { clearStateAndPersistedData } from '../../redux/store';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthSettingDetail } from '../../redux/services/AuthSettings';
import { reSetToken } from '../../redux/features/authSlice';
import { setTaskNotification, setNotification } from '../../redux/features/commonSlice'

const Header = () => {
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState({ tab1: false, tab2: false, tab3: false });
    const [notificationPermission, setNotificationPermission] = useState([])
    const [taskPermission, setTaskPermission] = useState([])

    const navigate = useNavigate();
    const mainLogo = useSelector(state => state?.AuthSettings?.settingInfo?.organization?.profile_img_org);
    const ProfileImage = useSelector(state => state?.AuthSettings?.settingInfo?.general?.profile_img_url);
    const general = useSelector(state => state?.AuthSettings?.settingInfo?.general);
    const currentUserRole = useSelector(state => state?.auth?.currentUserRole);
    const NotificationList = useSelector(state => state?.AuthNotification?.NotificationList);
    const moduleData = useSelector(state => state.common.moduleData);


    useEffect(() => {
        dispatch(getAuthSettingDetail());
        const handleToggle = (event) => {
            event.preventDefault();
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        };
        const sidebarToggle = document.body.querySelector('#sidebarToggle');
        if (sidebarToggle) {
            sidebarToggle.addEventListener('click', handleToggle);
        }
        return () => {
            if (sidebarToggle) {
                sidebarToggle.removeEventListener('click', handleToggle);
            }
        };
    }, []);


    useEffect(() => {
        if (moduleData.length > 0) {
            const notificationsModule = moduleData.find(item => item.module_name === 'Notifications');
            if (notificationsModule) {
                const permissionsArray = notificationsModule.permission.map(item => item.permission);
                setNotificationPermission(permissionsArray);
            }
            const tasksModule = moduleData.find(item => item.module_name === 'Tasks');
            if (tasksModule) {
                const permissionsArray = tasksModule.permission.map(item => item.permission);
                setTaskPermission(permissionsArray);
            }
        }
    }, [moduleData]);

    const toggleTab = (tab) => {
        setToggle(prevState => ({
            ...prevState,
            [tab]: !prevState[tab]
        }));
    };

    const Logout = () => {
        // window.location.reload();
        dispatch(reSetToken())
        localStorage.clear();
        clearStateAndPersistedData();
        navigate('/login');
    }

    // Filter notifications based on 'all' or 'unread'
    const filteredNotifications = NotificationList && NotificationList.filter(Notification => !Notification?.notificationAccess[0]?.msg_seen)

    return (
        <div className="myheader">
            <nav className="sb-topnav navbar navbar-expand">
                <Link className="navbar-brand ps-3" to="/">
                    <img className='headerimg' src={mainLogo ? mainLogo : logoDark} alt="LIFETRACKA" title="LIFETRACKA" />
                </Link>
                <button className="btn btn-link btn-sm me-4 me-lg-0" id="sidebarToggle" href="#!">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13 16H3C2.73478 16 2.48043 16.1054 2.29289 16.2929C2.10536 16.4804 2 16.7348 2 17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H13C13.2652 18 13.5196 17.8946 13.7071 17.7071C13.8946 17.5196 14 17.2652 14 17C14 16.7348 13.8946 16.4804 13.7071 16.2929C13.5196 16.1054 13.2652 16 13 16ZM3 8H21C21.2652 8 21.5196 7.89464 21.7071 7.70711C21.8946 7.51957 22 7.26522 22 7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7C2 7.26522 2.10536 7.51957 2.29289 7.70711C2.48043 7.89464 2.73478 8 3 8ZM21 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z"
                            fill="black" />
                    </svg>
                </button>
                <ul className="navbar-nav ms-auto">
                    {notificationPermission?.includes('View') &&
                        <li className="nav-item dropdown notification">
                            <a className='nav-link dropdown-toggle'>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => dispatch(setTaskNotification(true))}>
                                    <path
                                        d="M9.375 15L13.125 18.75L20.625 11.25M9.75 26.25H20.25C22.3502 26.25 23.4003 26.25 24.2025 25.8413C24.9081 25.4817 25.4817 24.9081 25.8413 24.2025C26.25 23.4003 26.25 22.3502 26.25 20.25V9.75C26.25 7.6498 26.25 6.5997 25.8413 5.79754C25.4817 5.09193 24.9081 4.51825 24.2025 4.15873C23.4003 3.75 22.3502 3.75 20.25 3.75H9.75C7.6498 3.75 6.5997 3.75 5.79754 4.15873C5.09193 4.51825 4.51825 5.09193 4.15873 5.79754C3.75 6.5997 3.75 7.6498 3.75 9.75V20.25C3.75 22.3502 3.75 23.4003 4.15873 24.2025C4.51825 24.9081 5.09193 25.4817 5.79754 25.8413C6.5997 26.25 7.6498 26.25 9.75 26.25Z"
                                        stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                        </li>
                    }
                    {taskPermission?.includes('View') &&
                        <li className="nav-item dropdown notification">
                            {filteredNotifications && filteredNotifications.length > 0 ?
                                <span></span>
                                :
                                null
                            }
                            <a className='nav-link dropdown-toggle'>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => dispatch(setNotification(true))}>
                                    <path
                                        d="M17.5 26.25H12.5M22.5 10C22.5 8.01088 21.7098 6.10322 20.3033 4.6967C18.8967 3.29018 16.9891 2.5 15 2.5C13.0108 2.5 11.1032 3.29018 9.69666 4.6967C8.29014 6.10322 7.49996 8.01088 7.49996 10C7.49996 13.8627 6.52555 16.5074 5.43704 18.2568C4.51887 19.7323 4.05979 20.4701 4.07662 20.6759C4.09526 20.9038 4.14354 20.9907 4.32718 21.127C4.49304 21.25 5.2407 21.25 6.73603 21.25H23.2639C24.7592 21.25 25.5069 21.25 25.6727 21.127C25.8564 20.9907 25.9047 20.9038 25.9233 20.6759C25.9401 20.4701 25.4811 19.7323 24.5629 18.2568C23.4744 16.5074 22.5 13.8627 22.5 10Z"
                                        stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                        </li>
                    }
                    <li className="nav-item dropdown profile">
                        <a onClick={() => toggleTab('tab3')} className={`nav-link dropdown-toggle ${toggle.tab3 ? 'show' : ''}`} id="navbarDropdown" href="#" role="button"
                            data-bs-toggle="dropdown" aria-expanded={`${toggle.tab3 ? "true" : "false"}`}>
                            <img src={ProfileImage ? ProfileImage : defaultImg} alt="LIFETRACKA" title="LIFETRACKA" />
                        </a>
                        <ul className={`dropdown-menu dropdown-menu-end ${toggle.tab3 ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href="#!">{`${general?.first_name} ${general?.last_name} (${general?.type}) ${currentUserRole ? `(${currentUserRole})` : ''}`}</a></li>
                            {/* <li><a className="dropdown-item" href="#!">Activity Log</a></li> */}
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li onClick={() => Logout()}><a className="dropdown-item" href="#!">Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Header;
