import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { destroy_user_from_home, user_without_assign_home, get_home_assign_users, destroy_member_from_home, get_home_assign_members,member_without_assign_home } from '../../../redux/services/AuthHomes';
import Modal from 'react-modal';
const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        maxWidth: '750px',
        // width: '100%',
        background: '#fff',
        borderRadius: '8px',
        padding: '20px',
        height: 'auto',
        zIndex: '99',
        transition: 'all 1s ease-in-out'
    }
};
const Delete_home_user = ({ delteModal, setDeleteModal, search,id, type }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async () => {
        const user_payload = {
            home_table_id:id,
            user_id: delteModal?.user_id
        };
        const member_payload = {
            home_table_id:id,
            member_id: delteModal?.member_id
        };
        if (type == 'delete_member') {
            const response = await dispatch(destroy_member_from_home(member_payload));
            // if (response?.payload?.status = true) {
                setDeleteModal({ modal: false });
                dispatch(get_home_assign_members(search));
                dispatch(member_without_assign_home(id));
            // }
        } else {
            const response = await dispatch(destroy_user_from_home(user_payload));
            // if (response?.payload?.status = true) {
                setDeleteModal({ modal: false });
                dispatch(get_home_assign_users(search));
                dispatch(user_without_assign_home(id));
            // }
        }

        //  navigate('/auth/homes');
    }
    return (
        <Modal
            isOpen={delteModal?.modal}
            onRequestClose={() => setDeleteModal({ ...delteModal, modal: false })}
            style={customModalStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Create Role Modal"
        >
            <div className="modal-dialog modal-lg communication_edit_modal">
                <div className="modal-content">
                    <div className="modal-header close-deactivate">
                        <button type="button" className="btn-close" onClick={() => setDeleteModal(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="font-18 hd-title">Delete {type=='delete_member'?'Member':'User'} </div>
                        <div className="hd-subtitle font-14">Are you sure you want to
                            delete this {type=='delete_member'?'Member':'User'} ?</div>
                        <div className="pop-action-btn">
                            <button type="button" className="btn btn-light" onClick={() => setDeleteModal(false)}>Cancel</button>
                            <button type="submit" className="btn btn-danger mx-2" onClick={() => onSubmit()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

    )
}

export default Delete_home_user;