import { createSlice } from "@reduxjs/toolkit";
import { getSubscriptionPlan, AddSubscriptionDetail, getSubscriptionPlanDiscount,CheckEmailPhone } from "../services/subscription-plan";
const initialState = {
    planInfo: {},
    isLoading: false,
    error: null,
    subscriptionPlanList: [],
    planDiscount: {}
};

const subscription = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        setSubscription: (state, action) => {
            state.planInfo = action?.payload;
        },
        reSetSubscription: (state, action) => {
            state.planInfo = {};
        },
    },
    extraReducers: (builder) => {
        // ========== Subscription plan detail============== //
        builder.addCase(getSubscriptionPlan.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(getSubscriptionPlan.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.subscriptionPlanList = action?.payload?.data
        });
        builder.addCase(getSubscriptionPlan.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ========== Add Subscription plan detail============== //
        builder.addCase(AddSubscriptionDetail.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(AddSubscriptionDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(AddSubscriptionDetail.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ========== Subscription plan Discount============== //
        builder.addCase(getSubscriptionPlanDiscount.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(getSubscriptionPlanDiscount.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.planDiscount = action?.payload?.data
        });
        builder.addCase(getSubscriptionPlanDiscount.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
          // ==========Check Email Phone Exist============== //
          builder.addCase(CheckEmailPhone.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(CheckEmailPhone.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(CheckEmailPhone.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });

    }
});
export const { setSubscription, reSetSubscription } = subscription?.actions;
export default subscription?.reducer;
