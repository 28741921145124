import React from "react";
import { useSelector } from 'react-redux';
import { DownArrowSvg, EditSvg, DeleteSvg, Mark_as_completeSvg, Mark_cancle_subSvg } from "../../../Images/ComonSvgs";
import { monthDayYearFormat } from '../../../components/Common/Common'

const StatusModal = ({ taskDetail, toggalStatusModal, onEditTask, onDeleteTask, onMarkAsComplate }) => {
    const general = useSelector(state => state?.AuthSettings?.settingInfo?.general);
    const userPermissions = useSelector(state => state.common.userPermissions);


    const handleMarkAsComplete = () => {
        let status = taskDetail?.task_status === "pending"
            ? 'pending approval'
            : taskDetail?.task_status === "pending approval"
                ? 'completed'
                : taskDetail?.task_status === "completed"
                    ? 'pending'
                    : 'pending'
        onMarkAsComplate(taskDetail, status);
    };

    const handleCancelSubmission = () => {
        onMarkAsComplate(taskDetail, "pending");
    };

    return (
        <div className="modal fade show appoint_ment task_Status_modal">
            <div className="modal-dialog modal-lg task_po">
                <div className="modal-content ">
                    <div className="modal-header task_heading">
                        <h5 className="modal-title" id="exampleModalLabel"></h5>
                        <button type="button" className="btn-close" onClick={() => toggalStatusModal(false)}></button>
                    </div>
                    <div className="modal-body appointment_modal">
                        <div className="apnorht_head">
                            <div className="apnorht_head_inner">
                                <h4 className="font-18 hd-title">{taskDetail?.title}</h4>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="status_modal_contant">
                                    <div className="left">
                                        <p className="task_status">Status</p>
                                        <p className="task_status">Assigned to</p>
                                        <p className="task_status">Due date</p>
                                    </div>
                                    <div className="right">
                                        <p className={`taxt_cap ${taskDetail?.task_status}`}>{taskDetail?.task_status} &nbsp; <DownArrowSvg />
                                        </p>
                                        <div className="task-footer">
                                            {taskDetail?.userAssigned?.profile_img_url ?
                                                <img src={taskDetail?.userAssigned?.profile_img_url} alt="" /> :
                                                <span className="name">{taskDetail?.userAssigned?.first_name?.charAt(0)?.toUpperCase() + taskDetail?.userAssigned?.last_name?.charAt(0)?.toUpperCase() || ''}</span>}
                                            <p>{`${taskDetail?.userAssigned?.first_name} ${taskDetail?.userAssigned?.last_name}`
                                            }</p>
                                        </div>
                                        <p>{monthDayYearFormat(taskDetail?.due_date, true)}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="status_modal_decs">
                                <div className="text-sm text-muted-foreground task_status">Description</div>
                                {/* <div className="task_description">
                                    {taskDetail.description.length > 100
                                    ? taskDetail.description.substring(0, 100) + '...'
                                    : taskDetail.description}
                                    </div> */}
                                    <div className="task_description">{taskDetail.description}</div>
                            </div>

                            {/* Footer buttons */}
                            {general.id === taskDetail?.created_by || general?.type === 'Owner' ?
                                <div className="cancel_delete task_fobtn">
                                    {taskDetail?.task_status !== 'completed' &&
                                    <>
                                    {userPermissions?.includes('Edit') &&
                                        <button type="button" className="btn btn-light width_100" onClick={() => onEditTask(taskDetail.id)}><EditSvg />Edit</button>
                                    }
                                    </>
                                    }
                                    &nbsp;
                                    {userPermissions?.includes('Delete') &&
                                    <button type="button" className="btn btn-light width_100" onClick={() => onDeleteTask(taskDetail?.id)}><DeleteSvg />
                                        Delete</button> 
}
                                        &nbsp;

                                    <button type="button" className="btn btn-primary text-white" onClick={() => handleMarkAsComplete()}>
                                        {taskDetail?.task_status !== 'completed' && <Mark_as_completeSvg />}
                                        {taskDetail?.task_status === 'completed' ? 'Reset Task' : taskDetail?.task_status === 'pending approval' ? 'Confirm task as complete' : 'Mark as complete'}
                                    </button>
                                </div>
                                :

                                <div className="cancel_delete task_fobtn">
                                    {taskDetail?.task_status === 'pending approval' &&
                                        <button type="button" className="btn btn-light" onClick={() => handleCancelSubmission()}>
                                            <Mark_cancle_subSvg /> Cancel submission
                                        </button>}
                                    <button type="button" className="btn btn-primary text-white" onClick={() => handleMarkAsComplete()} disabled={taskDetail?.task_status === 'completed' || taskDetail?.task_status === 'pending approval'}>
                                        {taskDetail?.task_status !== 'completed' && <Mark_as_completeSvg />}
                                        {taskDetail?.task_status === 'completed' ? 'Task complete' : taskDetail?.task_status === 'pending approval' ? 'Pending approval' : 'Mark as complete'}
                                    </button>
                                </div>

                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusModal;
