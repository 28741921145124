import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter';

const goals = [
  "Health",
  "Home Life",
  "Daily Life"
];

const IndividualizedGoals = ({ onHandleBackStep, onSubmit }) => {
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.individualGoals);

  // Initialize the form with useForm hook
  const { control, handleSubmit, register, setValue, formState: { errors } } = useForm({
    defaultValues: {
      goalsRequest: [{
        goal: "",
        support_needed_in_life_area: "",
        outcome: "",
        where_are_they_now_desc: "",
        goals: [{
          what_need_to_do: "",
          who_will_do: "",
          when_to_do: ""
        }]
      }],
    }
  });

  const { fields: goalsRequestFields, append: appendGoalsRequest, remove: removeGoalsRequest } = useFieldArray({
    control,
    name: 'goalsRequest'
  });

  // Example of dynamically setting values after fetching data
  useEffect(() => {
    if (allDddPcsp && allDddPcsp?.length > 0) {
      // Assume `allDddPcsp` contains data in the required structure
      const preloadedGoalsRequest = allDddPcsp.map(item => ({
        goal: item.goal || "",
        support_needed_in_life_area: item.support_needed_in_life_area || "",
        outcome: item.outcome || "",
        where_are_they_now_desc: item.where_are_they_now_desc || "",
        goals: item.memberdddpcspIndividualGoaloutcomesSuggestion || [{ what_need_to_do: "", who_will_do: "", when_to_do: "" }]
      }));
      // Set the preloaded data using setValue
      setValue("goalsRequest", preloadedGoalsRequest);
    }
  }, [allDddPcsp, setValue]);

  return (
    <div className="tab-pane fade goal_tab show active" id="v-pills-out" role="tabpanel" aria-labelledby="v-pills-out-tab">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-title font-24">What area of your life would you like the team to support you in?</div>
            <div className="hd-subtitle font-16">Goals are listed in order of priority. Use additional pages as needed
              and number each accordingly</div>
          </div>
        </div>

        {goalsRequestFields.map((member, goalsRequestIndex) => (
          <div key={member.id} className="row">
            {goalsRequestIndex > 0 && (
            <hr />
          )}
            <div className="col-12 mb-3">
              <select
                className="form-select"
                aria-label="Default select example"
                {...register(`goalsRequest.${goalsRequestIndex}.goal`, { required: "Goal is required!" })}
              >
                <option value="">Select</option>
                {goals.map((item, idx) => (
                  <option key={idx} value={idx}>{item}</option>
                ))}
              </select>
              {errors.goalsRequest && errors.goalsRequest[goalsRequestIndex]?.goal && (
                <span className="error">{errors.goalsRequest[goalsRequestIndex].goal.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label className="form-label">Goal #{goalsRequestIndex + 1}*</label>
              <input
                type="input"
                className="form-control"
                placeholder="Enter Goal"
                {...register(`goalsRequest.${goalsRequestIndex}.support_needed_in_life_area`, { required: "Goal is required!" })}
                maxLength={50}
              />
              {errors.goalsRequest && errors.goalsRequest[goalsRequestIndex]?.support_needed_in_life_area && (
                <span className="error">{errors.goalsRequest[goalsRequestIndex].support_needed_in_life_area.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label className="form-label">Outcome*</label>
              <textarea
                className="form-control"
                placeholder="Enter notes"
                {...register(`goalsRequest.${goalsRequestIndex}.outcome`, { required: "Outcome is required!" })}
                maxLength={150}
              />
              {errors.goalsRequest && errors.goalsRequest[goalsRequestIndex]?.outcome && (
                <span className="error">{errors.goalsRequest[goalsRequestIndex].outcome.message}</span>
              )}
            </div>
            <div className="col-12 mb-3">
              <label className="form-label">Where are they now (at the time of this plan)?*</label>
              <textarea
                className="form-control"
                placeholder="Enter notes"
                {...register(`goalsRequest.${goalsRequestIndex}.where_are_they_now_desc`, { required: "This is required!" })}
                maxLength={150}
              />
              {errors.goalsRequest && errors.goalsRequest[goalsRequestIndex]?.where_are_they_now_desc && (
                <span className="error">{errors.goalsRequest[goalsRequestIndex].where_are_they_now_desc.message}</span>
              )}
            </div>

            {/* Nested goals array */}
            <div className="col-12">
              <div className="row">
                <NestedGoals
                  nestIndex={goalsRequestIndex}
                  control={control}
                  register={register}
                  errors={errors}
                />
              </div>
            </div>

            {goalsRequestIndex > 0 && (
              <div className="col-12 mb-3">
                <a
                  href="#"
                  className="stepone_remove"
                  onClick={(e) => {
                    e.preventDefault();
                    removeGoalsRequest(goalsRequestIndex);
                  }}
                >
                  Remove
                </a>
              </div>
            )}
          </div>
        ))}

        <div className="stepone_health_planbtn">
          <button type="button" className="font-14" onClick={() => appendGoalsRequest({
            goal: "",
            support_needed_in_life_area: "",
            outcome: "",
            where_are_they_now_desc: "",
            goals: [{
              what_need_to_do: "",
              who_will_do: "",
              when_to_do: ""
            }]
          })}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            Add another health plan
          </button>
        </div>

        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

// NestedGoals Component to handle dynamic fields in the nested goals array
const NestedGoals = ({ nestIndex, control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `goalsRequest.${nestIndex}.goals`
  });

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id} className="row">
          {index > 0 && (
            <hr />
          )}
          <div className="col-12 mb-3">
            <label className="form-label">What needs to be done*</label>
            <input
              type="input"
              className="form-control"
              placeholder="Enter task"
              {...register(`goalsRequest.${nestIndex}.goals.${index}.what_need_to_do`, { required: "This field is required!" })}
              maxLength={50}
            />
            {errors.goalsRequest && errors.goalsRequest[nestIndex]?.goals?.[index]?.what_need_to_do && (
              <span className="error">{errors.goalsRequest[nestIndex].goals[index].what_need_to_do.message}</span>
            )}
          </div>

          <div className="col-md-6 mb-3">
            <label className="form-label">Who will do*</label>
            <input
              type="input"
              className="form-control"
              placeholder="Enter person"
              {...register(`goalsRequest.${nestIndex}.goals.${index}.who_will_do`, { required: "This field is required!" })}
              maxLength={20}
            />
            {errors.goalsRequest && errors.goalsRequest[nestIndex]?.goals?.[index]?.who_will_do && (
              <span className="error">{errors.goalsRequest[nestIndex].goals[index].who_will_do.message}</span>
            )}
          </div>

          <div className="col-md-6 mb-3">
            <label className="form-label">When*</label>
            <input
              type="input"
              className="form-control"
              placeholder="Enter time"
              {...register(`goalsRequest.${nestIndex}.goals.${index}.when_to_do`, { required: "This field is required!" })}
              maxLength={20}
            />
            {errors.goalsRequest && errors.goalsRequest[nestIndex]?.goals?.[index]?.when_to_do && (
              <span className="error">{errors.goalsRequest[nestIndex].goals[index].when_to_do.message}</span>
            )}
          </div>

          {index > 0 && (
            <div className="col-12 mb-3">
              <a
                href="#"
                className="stepone_remove"
                onClick={(e) => {
                  e.preventDefault();
                  remove(index);
                }}
              >
                Remove Task
              </a>
            </div>
          )}
        </div>
      ))}

      <div className="col-12 mb-3">
        <div className='stepone_health_planbtn'>
          <button
            type="button"
            className="font-14"
            onClick={() => append({ what_need_to_do: "", who_will_do: "", when_to_do: "" })}
          >
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            Add another task
          </button>
        </div>
      </div>
    </>
  )
}

export default IndividualizedGoals;
