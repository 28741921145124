import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Create_task } from '../../../redux/services/AuthTasks'
import { reSetEditTasksData } from '../../../redux/features/AuthTasksSlice'
import moment from 'moment'

const AddNewTask = ({ setAddNewTask, setTaskStatus, setTaskStatusModal, taskStatus = {} }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const { handleSubmit, register, setValue, setError, clearErrors, formState: { errors } } = useForm();
    const AllUsers = useSelector(state => state?.AuthTasks?.AllUsers);

    const [minDate, setMinDate] = useState('');
    const [daysRemaining, setDaysRemaining] = useState(0);
    const [selectedDueDate, setSelectedDueDate] = useState('');


    useEffect(() => {
        // dispatch(get_all_admin_home_users())
        const dtToday = new Date();

        const month = String(dtToday.getMonth() + 1).padStart(2, '0');
        const day = String(dtToday.getDate()).padStart(2, '0');
        const year = dtToday.getFullYear();

        const maxDate = `${year}-${month}-${day}`;

        setMinDate(maxDate);
    }, [])

    useEffect(() => {
        if (Object.keys(taskStatus).length > 0) {
            setSelectedDueDate(moment(taskStatus?.due_date).format('YYYY-MM-DD'))
            setValue('title', taskStatus?.title)
            setValue('assigned_to', taskStatus?.assigned_to)
            setValue('due_date', moment(taskStatus?.due_date).format('YYYY-MM-DD'))
            setValue('description', taskStatus?.description)
            let day = CalculateDay(taskStatus?.created_date, taskStatus?.due_date)
            setDaysRemaining(day);
            setTimeout(() => {
                setValue('reminder', taskStatus?.reminder)
            }, 0)
        }
    }, [taskStatus])


    const CalculateDay = (created_date, due_date) => {
        const date = created_date ? new Date(created_date) : new Date()
        const dueDate = new Date(due_date);
        // Calculate the difference in time
        const timeDifference = dueDate - date;
        // Convert the time difference to days
        const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return Math.min(daysLeft, 60)


    }

    const onSubmit = async (payload) => {
        let data = payload
        data.task_status = 'pending'

        if (Object.keys(taskStatus).length > 0) {
            data.task_id = taskStatus?.id
            data.task_status = taskStatus?.task_status
        }
        let response = await dispatch(Create_task(data))
        if (response?.payload?.status) {
            setAddNewTask(false)
            setTaskStatus({})
            setTaskStatusModal(false)
            dispatch(reSetEditTasksData())
        }
    }

    const handleSubmitButton = () => {
        if (selectedDueDate === '') {
            setError('due_date', { type: 'required', message: 'Due Date is required!' })
        }
    }

    const handleCancleClick = () => {
        // setTaskStatus({})
        if(Object.keys(taskStatus).length > 0){
            setTaskStatusModal(true)
        }
        setAddNewTask(false)
        dispatch(reSetEditTasksData())
        if (id) {
            navigate('/auth/tasks')
        }
    }

    const handleDueDate = (e) => {
        if (e.target.value !== '') {
            clearErrors('due_date')
            setSelectedDueDate(e.target.value)
            setValue('due_date', e.target.value)
            let day = CalculateDay(null, e.target.value)
            if (day < 1) {
                setValue('reminder', null)
            }else{
                setValue('reminder', 1)
            }
            setDaysRemaining(day);
        }
    }

    // Generate numbers from 1 to 60
    const numbers = Array.from({ length: daysRemaining }, (_, index) => index + 1);
    return (
        <>
            <div className="modal fade show new_medi_modal">
                <div className="modal-dialog modal-lg task_po">
                    <div className="modal-content ">
                        <div className="modal-header task_heading">
                            <button type="button" className="btn-close" onClick={() => handleCancleClick()}></button>
                        </div>
                        <div className="modal-body appointment_modal">
                            <div className="apnorht_head">
                                <div className="apnorht_head_inner h_task">
                                    <h4 className="font-18 hd-title">{`${Object.keys(taskStatus).length > 0 ? 'Update' : 'Create'} task`}</h4>
                                </div>
                            </div>

                            <form className="row" onSubmit={handleSubmit(onSubmit)}>

                                <div className="col-md-12 mb-3">
                                    <label for="" className="form-label">Title</label>
                                    <input type="input" className="form-control" id="" placeholder="Enter Title" {...register("title", { required: "true" })} maxLength={100} />
                                    {errors.title && errors.title.type === "required" && (<span className="error">Title is required!</span>)}
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label htmlFor="" className="form-label">Assigned to</label>
                                    <select className="form-select" aria-label="Default select example" name='assigned_to' {...register('assigned_to', { required: 'true' })} disabled={Object.keys(taskStatus).length > 0 ? true : false} >
                                        <option value="">-Please Select -</option>
                                        {AllUsers && AllUsers.length > 0 &&
                                            [...AllUsers] // Create a shallow copy of the array
                                                .sort((a, b) => {
                                                    // Sort by first name alphabetically (case-insensitive)
                                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                                    if (nameA < nameB) return -1;
                                                    if (nameA > nameB) return 1;
                                                    return 0;
                                                })
                                                .map((item) => (
                                                    <option key={item?.id} value={item?.id}>
                                                        {item?.first_name} {item?.last_name}
                                                    </option>
                                                ))
                                        }
                                    </select>
                                    {errors.assigned_to && errors.assigned_to.type === "required" && <span className="error">Assigned to request is required</span>}
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label for="" className="form-label">Due date</label>
                                    <input type="date" min={minDate} className="form-control" id="" placeholder="Due Date" value={selectedDueDate} onChange={(e) => handleDueDate(e)} onKeyDown={(e) => e.preventDefault()} />
                                    {errors.due_date && errors.due_date.type === "required" && (<span className="error">{errors.due_date.message}</span>)}
                                </div>

                                {numbers && numbers.length > 0 && <div className="col-md-6 mb-3">
                                    <label for="" className="form-label">Reminder</label>
                                    <select className="form-select" aria-label="Default select example"  {...register('reminder')}>
                                        {numbers.map(number => {
                                            return (
                                                <option key={number} value={number?.toString()}>
                                                    {number} {`${number === 1 ? 'Day' : 'Days'} Prior`}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    {/* {errors.reminder && errors.reminder.type === "required" && <span className="error">This is required</span>} */}
                                </div>}

                                <div className="col-md-12 mb-12 my-4">
                                    <label for="" className="form-label">Description</label>
                                    <textarea className="form-control" id="" rows="4"
                                        placeholder="Description" {...register('description')}></textarea>
                                </div>
                                <div className="cancel_delete">
                                    <button type="button" className="btn btn-light btn_min_width" onClick={() => handleCancleClick()}>Cancel</button> &nbsp;
                                    <button type="submit" className="btn btn-primary text-white btn_min_width" onClick={() => handleSubmitButton()}>{`${Object.keys(taskStatus).length > 0 ? 'Update' : 'Create'}`}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddNewTask