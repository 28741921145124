import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const PlanningMeetingMembers = ({ step, subStep, onHandleNextStep, onHandleBackStep, onSubmit, isGoBack }) => {
    const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.meetingInfo);
    const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);

    const { control, handleSubmit, register, setValue, formState: { errors } } = useForm({
        defaultValues: {
            planMeeting: [{ name: "", is_attending: "", phone_number: "", email: "" }],
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'planMeeting'
    });

    useEffect(() => {                
        if (UploadedPdfData?.length > 0 && !isGoBack) {
            UploadedPdfData.forEach((item) => {
                switch (item.key) {
                    case 'Meeting Location:': 
                    setValue('meeting_location', item?.value);
                    break
                    case 'Where did the previous meeting take place?': 
                    setValue('previous_meeting_location', item?.value);
                    break
                    case "List any changes to the member's contact information:": 
                    setValue('change_in_member_contact_information', item?.value);
                    break

                }
            })
        } else {
            // Planning meeting
            setValue('meeting_location', allDddPcsp?.meeting_location);
            setValue('is_member_ask_for_meeting_time_location', allDddPcsp?.is_member_ask_for_meeting_time_location);
            setValue('is_member_decide_meeting_location_outside', allDddPcsp?.is_member_decide_meeting_location_outside);
            setValue('previous_meeting_location', allDddPcsp?.previous_meeting_location);
            setValue('change_in_member_contact_information', allDddPcsp?.change_in_member_contact_information);

            const lengthDifference = allDddPcsp?.memberdddpcspmeetingmember?.length - fields.length;
            if (lengthDifference > 0) {
                for (let i = 0; i < lengthDifference; i++) {
                    append({});
                }
            }
            allDddPcsp?.memberdddpcspmeetingmember.forEach((item, index) => {
                setValue(`planMeeting.${index}.name`, item.name || '');
                setValue(`planMeeting.${index}.is_attending`, item.is_attending || '');
                setValue(`planMeeting.${index}.phone_number`, item.phone_number || '');
                setValue(`planMeeting.${index}.email`, item.email || '');
            });
        }
    }, [allDddPcsp, UploadedPdfData, isGoBack])

    return (
        <>
            <div className="member_vital_basic_title">
                <h4>Planning meeting members</h4>
            </div>
            <div className="vital_stepone_para">
                <p>Personal, Therapy, Specialists, HCBS Providers, etc.</p>
            </div>
            <div className="member_vitalstep_one_form">
                <form onSubmit={handleSubmit(onSubmit)} className="row">
                    {fields.map((member, index) => (
                        <div key={member.id} className="row">
                            {index > 0 && (
                                <hr />
                            )}
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Name*</label>
                                <input
                                    type="input"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    {...register(`planMeeting.${index}.name`, { required: "First Name is required!" })}
                                    maxLength={20}
                                />
                                {errors.planMeeting && errors.planMeeting[index]?.name && (
                                    <span className="error">{errors.planMeeting[index].name.message}</span>
                                )}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Attended meeting*</label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    {...register(`planMeeting.${index}.is_attending`, { required: "Attended meeting is required!" })}>
                                    <option value="">Select</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                    <option value="2">N/A</option>
                                </select>
                                {errors.planMeeting && errors.planMeeting[index]?.is_attending && (
                                    <span className="error">{errors.planMeeting[index].is_attending.message}</span>
                                )}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Phone number</label>
                                <input
                                    type="input"
                                    className="form-control"
                                    placeholder="000 0000 000"
                                    {...register(`planMeeting.${index}.phone_number`, {
                                        // required: "Phone number is required",
                                        pattern: { value: /^\d{10,15}$/, message: "Phone number must be 10 to 15 digits" }
                                    })}
                                    maxLength={15}
                                    onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                    onPaste={(event) => {
                                        // Prevent pasting any non-numeric characters
                                        const pastedData = event.clipboardData.getData('text');
                                        if (!/^\d{1,5}$/.test(pastedData)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {errors.planMeeting && errors.planMeeting[index]?.phone_number && (
                                    <span className="error">{errors.planMeeting[index].phone_number.message}</span>
                                )}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Email address*</label>
                                <input
                                    type="input"
                                    className="form-control"
                                    placeholder="Enter email address"
                                    {...register(`planMeeting.${index}.email`, {
                                        required: "Email is required!",
                                        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" }
                                    })}
                                    maxLength={50}
                                />
                                {errors.planMeeting && errors.planMeeting[index]?.email && (
                                    <span className="error">{errors.planMeeting[index].email.message}</span>
                                )}
                            </div>
                            {index > 0 && (
                                <div className="col-12 mb-3">
                                    <a
                                        href="#"
                                        className="stepone_remove"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            remove(index);
                                        }}
                                    >
                                        Remove
                                    </a>
                                </div>
                            )}
                            <hr />
                        </div>
                    ))}
                    <div className="stepone_health_planbtn">
                        <button
                            type="button"
                            className="font-14"
                            onClick={() =>
                                append({ id: Date.now() }) // Add a new member with a unique ID
                            }
                        >
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                            Add another meeting members
                        </button>
                    </div>
                    <hr />
                    <div className="col-12 mb-3">
                        <label className="form-label">Meeting Location*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Meeting location"
                            {...register('meeting_location', { required: "Meeting location is required" })}
                        />
                        {errors.meeting_location && (
                            <span className="error">{errors.meeting_location.message}</span>
                        )}
                    </div>
                    <div className="col-12 mb-3">
                        <label className="form-label">Was the member asked to decide when and where the meeting took place?*</label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            {...register('is_member_ask_for_meeting_time_location', { required: "Selection is required" })}
                        >
                            <option value="">Select</option>
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                            <option value="2">N/A</option>
                        </select>
                        {errors.is_member_ask_for_meeting_time_location && (
                            <span className="error">{errors.is_member_ask_for_meeting_time_location.message}</span>
                        )}
                    </div>
                    <div className="col-12 mb-3">
                        <label className="form-label">Did the member consider meeting locations outside of the home?*</label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            {...register('is_member_decide_meeting_location_outside', { required: "Selection is required" })}
                        >
                            <option value="">Select</option>
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                            <option value="2">N/A</option>
                        </select>
                        {errors.is_member_decide_meeting_location_outside && (
                            <span className="error">{errors.is_member_decide_meeting_location_outside.message}</span>
                        )}
                    </div>
                    <div className="col-12 mb-3">
                        <label className="form-label">Where did the previous meeting take place?*</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter previous meeting location"
                            {...register('previous_meeting_location', { required: "previous meeting location is required" })}
                        />
                        {errors.previous_meeting_location && (
                            <span className="error">{errors.previous_meeting_location.message}</span>
                        )}
                    </div>
                    <div className="col-12 mb-3">
                        <label className="form-label">List any changes to the member's contact information*</label>
                        <textarea className="form-control" placeholder="N/A" {...register("change_in_member_contact_information", { required: "member's contact information is required" })} maxLength={150} />
                        {errors.change_in_member_contact_information && (
                            <span className="error">{errors.change_in_member_contact_information.message}</span>
                        )}
                    </div>
                    <PcspFooter onHandleNextStep={onHandleNextStep} onHandleBackStep={onHandleBackStep} step={step} subStep={subStep} />
                </form>
            </div>
        </>
    );
};

export default PlanningMeetingMembers;
