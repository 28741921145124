import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';


const Header = ({ setAddNewTask, viewType, onViewChange, handleSearchBar, setSearch, search }) => {
	const currentUserRole = useSelector(state => state?.auth?.currentUserRole);
	const general = useSelector(state => state?.AuthSettings?.settingInfo?.general);
	const AllUsers = useSelector(state => state?.AuthTasks?.AllUsers);
	const userPermissions = useSelector(state => state.common.userPermissions);


	const [showFilter, setShowFilter] = useState(false);



	const handleStatusChange = (e) => {
		const { name, value } = e.target
		setSearch({
			...search,
			[name]: value,
		});
	}


	const clear_all_filters = () => {

		setSearch({
			...search,
			task_status: '',
			assigned_to: '',
			due_date: '',
			page: 1,
			listData: 10,
			searchBar: ''
		});
	}

	return (
		<>
			<div className="right-main-header adminstrat_btn_main">
				<h2>Tasks</h2>
				{(currentUserRole === 'admin' || general?.type === 'Owner') &&
				<>
				{userPermissions?.includes('Add') &&
					<div className="adminstrat_btn">
						<button type="button" className="btn btn-primary" onClick={() => setAddNewTask(true)}>
							<svg width="21" height="20" viewBox="0 0 21 20" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333" stroke="white"
									stroke-width="1.66667" stroke-linecap="round"
									stroke-linejoin="round"></path>
							</svg>Create new task</button>
					</div>
				}
				</>
				}
			</div>
			<div className="saf-img-upload task_header">
				{
					!showFilter ?
						<div className="table-btn-sec">
							<div className="form-group searchbar"><input type="text" name="" id="" className="form-control" placeholder="Search" onChange={(e) => handleSearchBar(e)} value={search?.searchBar} maxLength={20}/>
								<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</div>
							<div>
							<button type="button" className="btn btn-light" onClick={() => setShowFilter(true)}>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
								More Filters
							</button>
							</div>
						</div> : ''
				}
				{showFilter &&

					<div className="table-btn-sec more-filter">
						<button type="button" className="btn btn-light" onClick={() => (setShowFilter(false), clear_all_filters())}>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
							Close Filters
						</button>
						<button className='clear-filter' onClick={() => clear_all_filters()}>Clear all filters</button>
						<div className="filter-option">
							<div className="form-group searchbar">
								<label className="form-label">Search</label>
								<input type="text" name="" id="" className="form-control" placeholder="Search Task Name..." onChange={(e) => handleSearchBar(e)} value={search?.searchBar} maxLength={20}/>
								<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</div>

							<div className="form-group">
								<label className="form-label">Status</label>
								<select className="form-select" aria-label="Default select example" name="task_status" value={search?.task_status} onChange={(e) => (handleStatusChange(e))} >
									<option value="">Select</option>
									<option value="pending">pending</option>
									<option value="pending approval">pending approval</option>
									<option value="past due">Past due</option>
									<option value="completed">completed</option>
								</select>
							</div>


							<div className="form-group">
								<label for="" className="form-label">Assign to</label>
								<select className="form-select" aria-label="Default select example" name="assigned_to" value={search?.assigned_to} onChange={(e) => (handleStatusChange(e))} >
									<option value="">Select</option>
									{AllUsers && AllUsers.length > 0 && AllUsers.map((item) => <option value={item?.id}>{item?.first_name} {item?.last_name}</option>)}
								</select>
							</div>

							<div className="form-group">
								<label for="" className="form-label">Due date</label>
								<input type="date" className="form-control" id="" placeholder="Due Date" name='due_date' value={search?.due_date} onChange={(e) => (handleStatusChange(e))} onKeyDown={(e) => e.preventDefault()} />
							</div>
						</div>
					</div>

				}
			</div>

			<div className="member_day_main task_dropdown_view">
				<div className="member_day_btn">
					<select className="form-select" aria-label="Default select example" value={viewType} onChange={(e) => onViewChange(e.target.value)}>
						<option value='board_view'>Board View</option>
						<option value="list_view">List View</option>
					</select>
				</div>
			</div>
		</>
	)
}
export default Header