import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'


const consistencyList = [
  { id: 'use_complex_sentences', name: 'Use complex sentences' },
  { id: 'use_simple_sentences', name: 'Use simple sentences' },
  { id: 'signs', name: 'Signs' },
  { id: 'nods', name: 'Nods' },
  { id: 'gestures', name: 'Gestures' },
]
const Communication = ({ onHandleBackStep, onSubmit }) => {
  const [checkedCommunicationIds, setCheckedCommunicationIds] = useState([])

  const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.saftyneedsCommunication);
  const communication_skill = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.saftyneedsCommunication?.membersIndividualizedHealthSaftyneedsCommunicationSkill);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();


  useEffect(() => {
    if (communication_skill && communication_skill.length > 0) {
      let Ids = []
      communication_skill.map((item) => {
        Ids.push(item.communication_skill)
      })
      setCheckedCommunicationIds(Ids)
      setValue('communication_skill', Ids);
    }else {
      setCheckedCommunicationIds([])
      setValue('communication_skill', []);
    }
  }, [communication_skill])

  useEffect(() => {
    setValue('communication_device', allIndividualizedHealth?.communication_device);
    
  }, [allIndividualizedHealth])


  const handleConsistencyCheckboxChange = (id, type) => {
    let ids = [...checkedCommunicationIds]
    if (ids.includes(id)) {
      // Remove the item if it's already checked
      ids = ids.filter(item => item !== id)
    } else {
      // Add the item if it's not checked
      ids = [...ids, id]
    }
    setCheckedCommunicationIds(ids);
    setValue('communication_skill', ids);
  };
  return (
    <div className="tab-pane fade  goal_tab show active">
       <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12">
          <label for="" className="form-label">Communiation skills</label>
          <div className="ddd_steptwo_para">
            <div className="date-slot activity-sec">
              <ul className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                {consistencyList && consistencyList.length > 0 && consistencyList.map((item, index) => {
                  return (
                    <li className="list-group-item">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id={`btncheck${index}`}
                        autocomplete="off"
                        value={item.id}
                        onChange={() => handleConsistencyCheckboxChange(item?.id)}
                        checked={checkedCommunicationIds.includes(item?.id)}
                      />
                      <label className="btn btn-outline-primary" for={`btncheck${index}`}>{item.name}</label>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Describe augmentative communication devices*</label>

          <textarea className="form-control" placeholder="Enter Describe augmentative communication devices" {...register("communication_device", { required: "true" })} maxLength={150} />
          {errors.communication_device && errors.communication_device.type === "required" && (<span className="error">This is required!</span>)}
        </div>

        <Footer onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default Communication