import { createSlice } from "@reduxjs/toolkit";
import { Add_Member,get_races,get_all_members,edit_member,Delete_single_member,Delete_multiple_member,update_member, get_diagnosis, export_excel_file_member,upload_member_image, Planning_meeting_member, Responsible_person_contact_info, Meeting_notes, Profile_member, Get_ddd_pcsp_data, Summry_conversation, Medical_support_info, Behavioral_provider_info, Additional_provider, Medications, Vision_hearing_speech, get_vision_hearing_speech_list, Vision_hearing_speech_equipements, Medical_supplies, Preventative_screening_services, get_prematative_service_list, Home_life, Daily_life, Individualized_goals_comes, Activities_daily_living, Services_authorized, Services_authorized_non_paid, Services_authorized_non_altcs, get_all_type_risk, Health_medical_risks, Get_selected_option_risks, Risks_assessment, Modifications_plan, Action_plan_followup, Next_meeting_info, Informed_concent, get_all_provider_types, Get_all_individualized_health_data, Basic_information, Health_medical_first_step, Health_medical_second_step, Health_medical_third_step, Health_medical_fourth_step, Diet_food, Diet_beverage, Communication_form, Mobility_form, Personal_care_skills, Behavioral_concern, get_all_medical_disability, get_all_medical_diseases, Get_all_medical_history, medical_history, Medical_history_allergie, Medical_history_current_condition, Medical_history_treatment, Get_all_behaviour_modifying_medication, Behavior_modifying_medication_consent, Get_step_form_complete_or_not, Get_all_general_consent_authorization, General_consent_authorization, upload_pdf_dddpcsp
} from "../services/AuthMembers";
const initialState = {
    isLoading: false,
    error: null,
    raceList:[],
    AllMemberList:[],
    EditMemberList:[],
    diagnosisList:[],
    allDddPcsp:[],
    visionHearingList: [],
    prematativeServiceList:[],
    allTypeRisk:[],
    allSelectedOptionRisks:[],
    allProviderType:[],
    allIndividualizedHealth:[],
    allMedicalDisability:[],
    allMedicalDiseases:[],
    allMedicalHistory:[],
    allBehaviourModifyingMedication:[],
    allGeneralConsentAuthorization:[],
    steps:[],
    stepFormComplete:[],
    UploadedPdfData: [],
}

const AuthMember = createSlice({
    name: 'AuthMember',
    initialState,
    reducers: {
        addSteps: (state, action) => {
            state.steps= action?.payload
          },
          setPdfData: (state, action) => {
            state.UploadedPdfData= action?.payload
          },
          reSetAllDddPcsp: (state, action) => {
            state.allDddPcsp = action?.payload
          }
    },
    extraReducers: (builder) => {
        // ==========Create Member============== //
        builder.addCase(Add_Member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Add_Member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Add_Member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get races============== //
        builder.addCase(get_races.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_races.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.raceList = action?.payload?.data
        });
        builder.addCase(get_races.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get all members============== //
        builder.addCase(get_all_members.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_members.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.AllMemberList = action?.payload?.data
        });
        builder.addCase(get_all_members.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get edit member data============== //
        builder.addCase(edit_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(edit_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.EditMemberList = action?.payload?.data
        });
        builder.addCase(edit_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========delete all members============== //
        builder.addCase(Delete_multiple_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Delete_multiple_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Delete_multiple_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========delete single member============== //
        builder.addCase(Delete_single_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Delete_single_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Delete_single_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========update member ============== //
        builder.addCase(update_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(update_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(update_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========upload member image ============== //
        builder.addCase(upload_member_image.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(upload_member_image.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(upload_member_image.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get diagnosis============== //
        builder.addCase(get_diagnosis.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_diagnosis.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.diagnosisList = action?.payload?.data
        });
        builder.addCase(get_diagnosis.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========export member excel file============== //
        builder.addCase(export_excel_file_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(export_excel_file_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(export_excel_file_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Planning_meeting_member============== //
        builder.addCase(Planning_meeting_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Planning_meeting_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Planning_meeting_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Responsible_person_contact_info============== //
        builder.addCase(Responsible_person_contact_info.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Responsible_person_contact_info.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Responsible_person_contact_info.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Meeting_notes============== //
        builder.addCase(Meeting_notes.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Meeting_notes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Meeting_notes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Profile_member============== //
        builder.addCase(Profile_member.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Profile_member.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Profile_member.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get DDD/PCSP============== //
        builder.addCase(Get_ddd_pcsp_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Get_ddd_pcsp_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allDddPcsp = action?.payload?.data
        });
        builder.addCase(Get_ddd_pcsp_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========Summry_conversation============== //
         builder.addCase(Summry_conversation.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Summry_conversation.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Summry_conversation.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Medical_support_info============== //
        builder.addCase(Medical_support_info.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Medical_support_info.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Medical_support_info.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Behavioral_provider_info============== //
        builder.addCase(Behavioral_provider_info.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Behavioral_provider_info.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Behavioral_provider_info.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Additional_provider============== //
        builder.addCase(Additional_provider.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Additional_provider.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Additional_provider.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Medications============== //
        builder.addCase(Medications.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Medications.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Medications.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Vision_hearing_speech============== //
        builder.addCase(Vision_hearing_speech.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Vision_hearing_speech.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Vision_hearing_speech.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_vision_hearing_speech_list============== //
        builder.addCase(get_vision_hearing_speech_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_vision_hearing_speech_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.visionHearingList = action?.payload?.data
        });
        builder.addCase(get_vision_hearing_speech_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Vision_hearing_speech_equipements============== //
        builder.addCase(Vision_hearing_speech_equipements.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Vision_hearing_speech_equipements.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Vision_hearing_speech_equipements.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Medical_supplies============== //
        builder.addCase(Medical_supplies.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Medical_supplies.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Medical_supplies.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Preventative_screening_services============== //
        builder.addCase(Preventative_screening_services.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Preventative_screening_services.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Preventative_screening_services.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_prematative_service_list============== //
        builder.addCase(get_prematative_service_list.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_prematative_service_list.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.prematativeServiceList = action?.payload?.data
        });
        builder.addCase(get_prematative_service_list.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Home_life============== //
        builder.addCase(Home_life.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Home_life.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Home_life.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Daily_life============== //
        builder.addCase(Daily_life.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Daily_life.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Daily_life.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Individualized_goals_comes============== //
        builder.addCase(Individualized_goals_comes.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Individualized_goals_comes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Individualized_goals_comes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Activities_daily_living============== //
        builder.addCase(Activities_daily_living.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Activities_daily_living.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Activities_daily_living.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Services_authorized============== //
        builder.addCase(Services_authorized.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Services_authorized.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Services_authorized.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Services_authorized_non_paid============== //
        builder.addCase(Services_authorized_non_paid.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Services_authorized_non_paid.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Services_authorized_non_paid.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Services_authorized_non_altcs============== //
        builder.addCase(Services_authorized_non_altcs.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Services_authorized_non_altcs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Services_authorized_non_altcs.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========get_all_type_risk============== //
         builder.addCase(get_all_type_risk.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_type_risk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allTypeRisk = action?.payload?.data
        });
        builder.addCase(get_all_type_risk.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========Health_medical_risks============== //
         builder.addCase(Health_medical_risks.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Health_medical_risks.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Health_medical_risks.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Get_selected_option_risks============== //
        builder.addCase(Get_selected_option_risks.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Get_selected_option_risks.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allSelectedOptionRisks = action?.payload?.data
        });
        builder.addCase(Get_selected_option_risks.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Risks_assessment============== //
        builder.addCase(Risks_assessment.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Risks_assessment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Risks_assessment.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Modifications_plan============== //
        builder.addCase(Modifications_plan.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Modifications_plan.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Modifications_plan.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Action_plan_followup============== //
        builder.addCase(Action_plan_followup.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Action_plan_followup.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Action_plan_followup.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Next_meeting_info============== //
        builder.addCase(Next_meeting_info.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Next_meeting_info.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Next_meeting_info.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Informed_concent============== //
        builder.addCase(Informed_concent.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Informed_concent.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Informed_concent.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========get_all_provider_types============== //
         builder.addCase(get_all_provider_types.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_provider_types.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allProviderType = action?.payload?.data
        });
        builder.addCase(get_all_provider_types.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Get_all_individualized_health_data============== //
        builder.addCase(Get_all_individualized_health_data.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Get_all_individualized_health_data.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allIndividualizedHealth = action?.payload?.data
        });
        builder.addCase(Get_all_individualized_health_data.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========Basic_information============== //
         builder.addCase(Basic_information.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Basic_information.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Basic_information.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Health_medical_first_step============== //
        builder.addCase(Health_medical_first_step.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Health_medical_first_step.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Health_medical_first_step.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Health_medical_second_step============== //
        builder.addCase(Health_medical_second_step.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Health_medical_second_step.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Health_medical_second_step.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Health_medical_third_step============== //
        builder.addCase(Health_medical_third_step.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Health_medical_third_step.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Health_medical_third_step.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Health_medical_fourth_step============== //
        builder.addCase(Health_medical_fourth_step.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Health_medical_fourth_step.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Health_medical_fourth_step.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Diet_food============== //
        builder.addCase(Diet_food.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Diet_food.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Diet_food.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Diet_beverage============== //
        builder.addCase(Diet_beverage.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Diet_beverage.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Diet_beverage.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Communication_form============== //
        builder.addCase(Communication_form.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Communication_form.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Communication_form.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Mobility_form============== //
        builder.addCase(Mobility_form.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Mobility_form.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Mobility_form.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Personal_care_skills============== //
        builder.addCase(Personal_care_skills.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Personal_care_skills.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Personal_care_skills.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Behavioral_concern============== //
        builder.addCase(Behavioral_concern.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Behavioral_concern.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Behavioral_concern.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_medical_disability============== //
        builder.addCase(get_all_medical_disability.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_medical_disability.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allMedicalDisability = action?.payload?.data
        });
        builder.addCase(get_all_medical_disability.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========get_all_medical_diseases============== //
        builder.addCase(get_all_medical_diseases.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(get_all_medical_diseases.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allMedicalDiseases = action?.payload?.data
        });
        builder.addCase(get_all_medical_diseases.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Get_all_medical_history============== //
        builder.addCase(Get_all_medical_history.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Get_all_medical_history.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allMedicalHistory = action?.payload?.data
        });
        builder.addCase(Get_all_medical_history.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========medical_history============== //
        builder.addCase(medical_history.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(medical_history.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(medical_history.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Medical_history_allergie============== //
        builder.addCase(Medical_history_allergie.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Medical_history_allergie.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Medical_history_allergie.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Medical_history_current_condition============== //
        builder.addCase(Medical_history_current_condition.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Medical_history_current_condition.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Medical_history_current_condition.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Medical_history_treatment============== //
        builder.addCase(Medical_history_treatment.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Medical_history_treatment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Medical_history_treatment.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
         // ==========Get_all_behaviour_modifying_medication============== //
         builder.addCase(Get_all_behaviour_modifying_medication.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Get_all_behaviour_modifying_medication.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allBehaviourModifyingMedication = action?.payload?.data
        });
        builder.addCase(Get_all_behaviour_modifying_medication.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Behavior_modifying_medication_consent============== //
        builder.addCase(Behavior_modifying_medication_consent.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Behavior_modifying_medication_consent.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(Behavior_modifying_medication_consent.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========Get_step_form_complete_or_not============== //
        builder.addCase(Get_step_form_complete_or_not.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Get_step_form_complete_or_not.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.stepFormComplete = action?.payload?.data
        });
        builder.addCase(Get_step_form_complete_or_not.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
          // ==========Get_all_general_consent_authorization============== //
          builder.addCase(Get_all_general_consent_authorization.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(Get_all_general_consent_authorization.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.allGeneralConsentAuthorization = action?.payload?.data
        });
        builder.addCase(Get_all_general_consent_authorization.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========General_consent_authorization============== //
        builder.addCase(General_consent_authorization.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(General_consent_authorization.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(General_consent_authorization.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
        // ==========upload_pdf_dddpcsp============== //
        builder.addCase(upload_pdf_dddpcsp.pending, (state) => {
            state.isLoading = true;
            state.error = false;
        });
        builder.addCase(upload_pdf_dddpcsp.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = false;
        });
        builder.addCase(upload_pdf_dddpcsp.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action?.payload;
        });
    }
})

// Export the synchronous action
export const { addSteps, setPdfData, reSetAllDddPcsp } = AuthMember.actions;

export default AuthMember?.reducer;