import React,{useEffect} from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const MeetingNotes = ({onHandleBackStep, onSubmit, isGoBack}) => {
    const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.meetingInfo);
    const UploadedPdfData = useSelector(state => state?.AuthMember?.UploadedPdfData);
    // Initialize the form with useForm hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        if (UploadedPdfData?.length > 0 && !isGoBack) {
            UploadedPdfData.forEach((item) => {
                switch (item.key) {
                    case 'MEETING NOTES OR SPECIAL CONSIDERATIONS:':
                        setValue('meeting_notes', item?.value);
                        break
                }
            })
        } else {
            // Meeting notes
            setValue('meeting_notes', allDddPcsp?.memberdddpcspmeetingresponsibleperson?.meeting_notes);
        }

    }, [allDddPcsp, UploadedPdfData])

    return (
        <>
            <div className="member_vitalstep_one_form">
                <form onSubmit={handleSubmit(onSubmit)} className="row">
                    <div className="col-12">
                        <div className="member_vital_basic_title">
                            <h4>Meeting notes or special considerations*</h4>
                        </div>
                        <div className="vital_stepone_para">
                            <p>Notes taken during the meeting*</p>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <textarea className="form-control" placeholder="Enter Meeting notes" {...register("meeting_notes", { required: "true" })} maxLength={150} />
                        {errors.meeting_notes && errors.meeting_notes.type === "required" && (<span className="error">Meeting notes is required!</span>)}

                    </div>
                    <PcspFooter onHandleBackStep={onHandleBackStep} />
                </form>
            </div>
        </>
    )
}

export default MeetingNotes
