import React from 'react';

export const DocumentsSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6666 8.75008V5.66675C16.6666 4.26662 16.6666 3.56655 16.3942 3.03177C16.1545 2.56137 15.772 2.17892 15.3016 1.93923C14.7668 1.66675 14.0668 1.66675 12.6666 1.66675H7.33331C5.93318 1.66675 5.23312 1.66675 4.69834 1.93923C4.22793 2.17892 3.84548 2.56137 3.6058 3.03177C3.33331 3.56655 3.33331 4.26662 3.33331 5.66675V14.3334C3.33331 15.7335 3.33331 16.4336 3.6058 16.9684C3.84548 17.4388 4.22793 17.8212 4.69834 18.0609C5.23312 18.3334 5.93318 18.3334 7.33331 18.3334H9.99998M11.6666 9.16675H6.66665M8.33331 12.5001H6.66665M13.3333 5.83341H6.66665M15 17.5001V12.5001M12.5 15.0001H17.5" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const CrossSvg = () => (
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667"
            stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
);

export const SearchSvg = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
            stroke="#667085" stroke-width="1.66667" stroke-linecap="round"
            stroke-linejoin="round"></path>
    </svg>
);

export const AfterSearchSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_15402_4549)">
            <path
                d="M6.06016 6.00016C6.2169 5.55461 6.52626 5.1789 6.93347 4.93958C7.34067 4.70027 7.81943 4.61279 8.28495 4.69264C8.75047 4.77249 9.17271 5.01451 9.47688 5.37585C9.78106 5.73718 9.94753 6.19451 9.94683 6.66683C9.94683 8.00016 7.94683 8.66683 7.94683 8.66683M8.00016 11.3335H8.00683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016Z"
                stroke="#98A2B3" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round"></path>
        </g>
        <defs>
            <clipPath id="clip0_15402_4549">
                <rect width="16" height="16" fill="white"></rect>
            </clipPath>
        </defs>
    </svg>
);