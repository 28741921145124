import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Get_all_medical_history, medical_history, Medical_history_allergie, Medical_history_current_condition, Medical_history_treatment } from '../../../../redux/services/AuthMembers';
import { addSteps } from '../../../../redux/features/AuthMembersSlice';

import Header from './Header';
import SideBar from './SideBar';
import {AfterAddMember} from '../AfterAddMember'

// Import forms
import MedicalHistoryForm from './Forms/MedicalHistoryForm';
import ImmunizationsAllergiesSeizures from './Forms/ImmunizationsAllergiesSeizures';
import Treatments from './Forms/Treatments';
import CurrentConditions from './Forms/CurrentConditions';

const MEDICALHISTORYFORMS = [
    'MedicalHistoryForm',
    'ImmunizationsAllergiesSeizures',
    'CurrentConditions',
    'Treatments',
];

const formSubSteps = {
    MedicalHistoryForm: 1,
    ImmunizationsAllergiesSeizures: 1,
    CurrentConditions: 1,
    Treatments: 1,
};

const formComponents = {
    MedicalHistoryForm,
    ImmunizationsAllergiesSeizures,
    CurrentConditions,
    Treatments,
};

const MedicalHistory = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [subStep, setSubStep] = useState(0);
    const [isGoBack, setIsGoBack] = useState(false)
    const [showPopUp, setShowPopUp] = useState({
        modal: false,
        message: '',
        id: ''
      })
    const totalSteps = MEDICALHISTORYFORMS.length;

    const getSubSteps = (formName) => formSubSteps[formName] || 1;
    const allMedicalHistory = useSelector(state => state?.AuthMember?.allMedicalHistory);
    const stepForm = useSelector(state => state?.AuthMember?.allMedicalHistory?.stepForm);

    const get_all_medical_history = () => {
        dispatch(Get_all_medical_history(id))
    }
    useEffect(() => {
        get_all_medical_history()
    }, [])


    useEffect(() => {
        if(stepForm && !isGoBack){
            setSubStep((Number(stepForm?.sub_step_no) === 0 || stepForm?.sub_step_no === null) ? 0 : Number(stepForm?.sub_step_no) - 1);
            setStep((Number(stepForm?.step_no) === 0 || stepForm?.step_no) === null ? 0 : Number(stepForm?.step_no) - 1);
        }
    }, [stepForm]);

    const handleNextStep = () => {
        const currentSubSteps = getSubSteps(MEDICALHISTORYFORMS[step]);
        if (subStep < currentSubSteps - 1) {
            setSubStep(subStep + 1);
        } else if (step < totalSteps - 1) {
            setStep(step + 1);
            setSubStep(0);
        }
    };

    const handleBackStep = () => {
        if (subStep > 0) {
            setSubStep(subStep - 1);
        } else if (step > 0) {
            setStep(step - 1);
            setSubStep(getSubSteps(MEDICALHISTORYFORMS[step - 1]) - 1);
        }
        setIsGoBack(true)
        get_all_medical_history()
    };

    const handleCrossClick = () => {
        navigate(`/auth/edit-member/${id}`);
    };

    const onSubmit = async (data) => {
        let payload = {
            profile_status: 1,
            step_no: step + 1,
            sub_step_no: subStep + 1,
            member_id: id,
            ...data,
        };
        if (MEDICALHISTORYFORMS[step] === 'MedicalHistoryForm') {
            let developmentDisability = [];
            let medicalHistory = [];
            if (data && data.developmental_disability.length > 0) {
                developmentDisability = data.developmental_disability.map((item) => ({
                    development_disability_id: item
                }));
            }
            if (data && data.medicalHistory.length > 0) {
                medicalHistory = data.medicalHistory.map((item) => ({
                    master_famhistory_id: item
                }));
            }
            payload = { ...payload, developmentDisability, medicalHistory };
        }
        if (MEDICALHISTORYFORMS[step] === 'CurrentConditions') {
            let serhostresiginjill = [];
            if (data && data.serhostresiginjill) {
                serhostresiginjill = data.serhostresiginjill.map((item) => ({
                    master_serhostresiginjill_id: item
                }));
            }
            payload = { ...payload, serhostresiginjill };
        }
        try {
            let response;
            switch (MEDICALHISTORYFORMS[step]) {
                case 'MedicalHistoryForm':
                    delete payload.developmental_disability
                    response = await dispatch(medical_history(payload));
                    break;
                case 'ImmunizationsAllergiesSeizures':
                    response = await dispatch(Medical_history_allergie(payload));
                    break;
                case 'CurrentConditions':
                    response = await dispatch(Medical_history_current_condition(payload));
                    break;
                case 'Treatments':
                    payload.profile_status = 0
                    response = await dispatch(Medical_history_treatment(payload));
                    if(response?.payload?.status){
                        setShowPopUp({ modal: true, message: response?.payload?.message, id: id })
                    }
                    break;
                // Add additional cases for other forms as needed
                default:
                    break;
            }
            if (response?.payload?.status) {
                handleNextStep();
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };
    
    useEffect(()=>{
        const data = {
            step: step,
            subStep: subStep,
            totalSteps: totalSteps,
            totalSubSteps: getSubSteps(MEDICALHISTORYFORMS[step])
        }
        dispatch(addSteps(data))
    },[totalSteps,step, subStep])
    const renderForm = () => {
        const FormComponent = formComponents[MEDICALHISTORYFORMS[step]];
        return FormComponent ? (
            <FormComponent
                step={step}
                subStep={subStep}
                totalSubSteps={getSubSteps(MEDICALHISTORYFORMS[step])}
                onHandleBackStep={handleBackStep}
                onSubmit={onSubmit}
            />
        ) : null;
    };

    return (
        <section className="step-home-sec container member_vitalinfo_stepone">
            <Header currentStep={step + 1} totalSteps={totalSteps} onClickCross={handleCrossClick} />
            <div className="pills-steps">
                <SideBar currentStep={step} MEDICALHISTORYFORMS={MEDICALHISTORYFORMS} />
                <div className="tab-content" id="v-pills-tabContent">
                    {renderForm()}
                </div>
            </div>
            <AfterAddMember setShowPopUp={setShowPopUp} showPopUp={showPopUp} type= 'MedicalHistory'/>
        </section>
    );
};

export default MedicalHistory;
