import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Footer from '../../Footer'

const consistencyList = [
  { id: 'Normal', name: 'Normal' },
  { id: 'Chopped', name: 'Chopped' },
  { id: 'Pureed', name: 'Pureed' },
  { id: 'Other', name: 'Other' }
]
const Food = ({ onHandleBackStep, onSubmit }) => {
  const [checkedconsistencyIds, setCheckedconsistencyIds] = useState([])

  const allIndividualizedHealth = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.dietFood);
  const consistancyOfFood = useSelector(state => state?.AuthMember?.allIndividualizedHealth?.dietFood?.membersIndividualizedhealthSaftyneedsDietfoodConsistancy);
  // Initialize the form with useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    if (consistancyOfFood && consistancyOfFood.length > 0) {
      let Ids = []
      consistancyOfFood.map((item) => {
        Ids.push(item.consistancy_of_food)
      })
      setCheckedconsistencyIds(Ids)
      setValue('consistancy_of_food', Ids);
    }else{
      setCheckedconsistencyIds([])
      setValue('consistancy_of_food', []);
    }
  }, [consistancyOfFood])

  useEffect(() => {
    setValue('all_utensils', allIndividualizedHealth?.all_utensils);
    setValue('specific_utensils', allIndividualizedHealth?.specific_utensils);
    setValue('limited_assistance', allIndividualizedHealth?.limited_assistance);
    setValue('significant_assistance', allIndividualizedHealth?.significant_assistance);
    setValue('other', allIndividualizedHealth?.other);
    setValue('chocking_hazard', allIndividualizedHealth?.chocking_hazard);
    setValue('special_diet', allIndividualizedHealth?.special_diet);
    setValue('monitoring', allIndividualizedHealth?.monitoring);

  }, [allIndividualizedHealth])

  const handleConsistencyCheckboxChange = (id, type) => {
    let ids = [...checkedconsistencyIds]
    if (ids.includes(id)) {
      // Remove the item if it's already checked
      ids = ids.filter(item => item !== id)
    } else {
      // Add the item if it's not checked
      ids = [...ids, id]
    }
    setCheckedconsistencyIds(ids);
    setValue('consistancy_of_food', ids);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row">
      <div className="col-12">
        <div className="alert alert-light sap-alert-heading">
          <div className="hd-title font-24">Food</div>
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Independent with all utensils*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`all_utensils`, { required: "Independent with all utensils is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.all_utensils && (
            <span className="error">{errors.all_utensils.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Independent with specific utensils*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`specific_utensils`, { required: "Independent with specific utensils is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.specific_utensils && (
            <span className="error">{errors.specific_utensils.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Requires limited assistance*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`limited_assistance`, { required: "Requires limited assistance is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.limited_assistance && (
            <span className="error">{errors.limited_assistance.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <label for="" className="form-label">Requires significant assistance*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`significant_assistance`, { required: "Requires significant assistance is required!" })}>
            <option value="">Select</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          {errors.significant_assistance && (
            <span className="error">{errors.significant_assistance.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Consistency of food</label>
        <div className="ddd_steptwo_para">
          <div className="date-slot activity-sec">
            <ul className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
              {consistencyList && consistencyList.length > 0 && consistencyList.map((item, index) => {
                return (
                  <li className="list-group-item">
                    <input
                      type="checkbox"
                      className="btn-check"
                      id={`btncheck${index}`}
                      autocomplete="off"
                      value={item.id}
                      onChange={() => handleConsistencyCheckboxChange(item?.id)}
                      checked={checkedconsistencyIds.includes(item?.id)}
                    />
                    <label className="btn btn-outline-primary" for={`btncheck${index}`}>{item.name}</label>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Other (Specify other food consistency)*</label>
        <div className="ddd_steptwo_para">
          {/* <input
            type="text"
            className="form-control"
            placeholder="other"
            {...register(`other`, { required: "Other is required!" })}
            maxLength={150}
          /> */}
          <textarea
          className="form-control"
          placeholder="other"
          {...register(`other`, { required: "Other is required!" })}
          maxLength={150}/>

          {errors?.other && (
            <span className="error">{errors.other.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Does this individual present a choking hazard*</label>
        <div className="ddd_steptwo_para">
          <select
            className="form-select"
            aria-label="Default select example"
            {...register(`chocking_hazard`, { required: "This is required!" })}>
            <option value="">Select</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
          {errors.chocking_hazard && (
            <span className="error">{errors.chocking_hazard.message}</span>
          )}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Special diet (Describe)*</label>
        <div className="ddd_steptwo_para">
          <textarea className="form-control" placeholder="Enter Special diet" {...register("special_diet", { required: "true" })} maxLength={150} />
          {errors.special_diet && errors.special_diet.type === "required" && (<span className="error">This is required!</span>)}
        </div>
      </div>
      <div className="col-12">
        <label for="" className="form-label">Monitoring*</label>
        <div className="ddd_steptwo_para">
          <textarea className="form-control" placeholder="Enter Monitoring" {...register("monitoring", { required: "true" })} maxLength={150} />
          {errors.monitoring && errors.monitoring.type === "required" && (<span className="error">This is required!</span>)}
        </div>
      </div>
      <Footer onHandleBackStep={onHandleBackStep} />
    </form>
  )
}
export default Food