import React, { useEffect, useState, useRef } from 'react'
import MAR from './MAR';
import { AppointmentMember } from './Appointment';
import Documents from './Documents';
import { Communication } from './Communication';
import { Forms } from './Forms';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Activate_homes } from '../../../redux/services/AuthHomes';
import { edit_member } from '../../../redux/services/AuthMembers';
import { get_languages, getState, getCountry } from '../../../redux/services/common';
import { get_races, update_member, upload_member_image } from '../../../redux/services/AuthMembers';
import defaultImg from "../../../Images/defaultImg.png";
import { useForm } from 'react-hook-form';
import DeleteMember from './DeleteMember';
const EditMember = () => {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors }, reset, control } = useForm();
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('')
    const inputFile = useRef(null);
    const [activeTab, setActiveTab] = useState('vitalInfo');
    const [deleteModal, setDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const id = useParams();
    const navigate = useNavigate();
    //get all active homes;
    const homeslist = useSelector(state => state?.AuthHomes?.ActivateHomes);
    //language list
    const LanguageList = useSelector(state => state?.common?.languageData);
    //get organization coutry id
    const CountryId = useSelector(state => state?.AuthSettings?.settingInfo?.organization?.country_id);
    //get all states
    const stateData = useSelector(state => state?.common?.StateData);
    //get all countrys for Citizenship
    const CountryData = useSelector(state => state?.common?.CountryData);
    //get race data
    const raceList = useSelector(state => state?.AuthMember?.raceList);
    //get edit member data
    const EditMemberList = useSelector(state => state?.AuthMember?.EditMemberList);
    //get diagnosis
    const diagnosisList = useSelector(state => state?.AuthMember?.diagnosisList);

    const userPermissions = useSelector(state => state.common.userPermissions);


    //common api call 
    useEffect(() => {
        dispatch(Activate_homes());
        dispatch(get_languages());
        dispatch(getCountry());
        dispatch(get_races());
        dispatch(getState(CountryId));
        dispatch(edit_member(id?.id))
    }, [])
    //get edit member data
    useEffect(() => {
        setValue('birthPlace', EditMemberList?.birthPlace);
        setValue('cellNo', EditMemberList?.cellNo);
        setValue('citizenship', EditMemberList?.citizenship);
        setValue('city', EditMemberList?.city);
        setValue('eyeColor', EditMemberList?.eyeColor);
        setValue('first_name', EditMemberList?.first_name);
        setValue('gendar', EditMemberList?.gendar);
        setValue('groupHome', EditMemberList?.groupHome);
        setValue('hairColor', EditMemberList?.hairColor);
        setValue('height', EditMemberList?.height);
        setValue('memberConditionInfo', EditMemberList?.memberConditionInfo);
        setValue('last_name', EditMemberList?.last_name);
        setValue('phoneNo', EditMemberList?.phoneNo);
        setValue('primaryDiagnosis', EditMemberList?.primaryDiagnosis);
        setValue('ahss_id', EditMemberList?.ahss_id);
        setValue('primaryLanguage', EditMemberList?.primaryLanguage);
        setValue('race', EditMemberList?.race);
        setValue('religiousPreference', EditMemberList?.religiousPreference);
        setValue('secondaryDiagnosis', EditMemberList?.secondaryDiagnosis);
        setValue('streetAddress', EditMemberList?.streetAddress);
        setValue('weight', EditMemberList?.weight);
        setValue('zipCode', EditMemberList?.zipCode);
        setValue('profile_pic', EditMemberList?.profile_pic);
        setImageUrl(EditMemberList?.profile_pic)
        setTimeout(() => {
            setValue('admissionDate', EditMemberList?.admissionDate);
            setValue('state', EditMemberList?.state);
        }, 1000);
    }, [EditMemberList])
    //change tab
    const changeTab = (name) => {
        setActiveTab(name)
    }

    const onSubmit = async (data) => {
        data.id = id?.id
        try {
            const response = await dispatch(update_member(data))
            if (response?.payload?.status == true) {
                navigate('/auth/members')
            }
        } catch (error) {
            console.log('error during update member', error);

        }
    }
    const EditVitalInfo = () => {
        navigate(`/auth/edit-members/${id?.id}`)
    }
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const handleFileUpload = async (e) => {
        const { files } = e.target;
        if (files && files.length) {
            const selectedFile = files[0];
            setFile(selectedFile);
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
            const newImageUrl = URL.createObjectURL(selectedFile);
            setImageUrl(newImageUrl);
        }
        try {
            const form = new FormData();
            form.append("member_id", id.id);
            form.append("profile_pic", files[0]);
            await dispatch(upload_member_image(form))
        } catch (error) {
            console.log('error during update member', error);

        }
    }

    const sortedCountryData = CountryData ?
        [CountryData.find(elem => elem.iso2 === "US"),
        ...CountryData.filter(elem => elem.iso2 !== "US")]
        : [];

    const fullName = `${EditMemberList?.first_name} ${EditMemberList?.last_name}`
    return (
        <div id="layoutSidenav_content">
            <main>
                <div className="vital_member_sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-12">
                                <div className="right-main-sec">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/auth/dashboard">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6.77168 11.6663C7.14172 13.104 8.4468 14.1663 10 14.1663C11.5532 14.1663 12.8583 13.104 13.2283 11.6663M9.18141 2.30297L3.52949 6.6989C3.15168 6.99275 2.96278 7.13968 2.82669 7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383 7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99275 16.4705 6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z"
                                                        stroke="#667085" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active"><Link to="/auth/members">Members</Link> </li>
                                        <li className="breadcrumb-item active">{fullName || 'N/A'}</li>
                                    </ol>
                                    <div className="mjohan_cont">
                                        {userPermissions?.includes('Delete') &&
                                            <div className="mjohan_inner">
                                                <button type="button" className="btn btn-outline-danger" onClick={() => setDeleteModal(true)}>Delete member</button>
                                            </div>
                                        }
                                        <div className="saf-img-upload edit-member-sec">
                                            <div className="mavtar_img">
                                                <img height="100px" width="100px" src={imageUrl && imageUrl.length > 0 ? imageUrl : defaultImg} alt="defaultImg.png" />
                                                <div className='upload-icon-sec'>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onButtonClick}>
                                                        <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <input style={{ display: "none" }} ref={inputFile} onChange={handleFileUpload} type="file" accept="image/jpeg, image/png" />
                                        </div>
                                        <h4>{fullName || 'N/A'}</h4>
                                        <p className='edit_member_decs'>{EditMemberList?.memberConditionInfo || 'N/A'}</p>
                                    </div>
                                    <div className="setting-tabs">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                    data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                    aria-selected="true" onClick={() => changeTab('vitalInfo')}>Vital Information</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link " id="profile-tab" data-bs-toggle="tab"
                                                    data-bs-target="#profile" type="button" role="tab"
                                                    aria-controls="profile" aria-selected="false" onClick={() => changeTab('MAR')}>MAR</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link " id="contact-tab" data-bs-toggle="tab"
                                                    data-bs-target="#contact" type="button" role="tab"
                                                    aria-controls="contact" aria-selected="false" onClick={() => changeTab('appointment')}>Appointment</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link " id="role-tab" data-bs-toggle="tab"
                                                    data-bs-target="#role" type="button" role="tab" aria-controls="role"
                                                    aria-selected="false" onClick={() => changeTab('communication')}>Communications</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link " id="billing-tab" data-bs-toggle="tab"
                                                    data-bs-target="#billing" type="button" role="tab"
                                                    aria-controls="billing" aria-selected="false" onClick={() => changeTab('documents')}>Documents</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link " id="form-tab" data-bs-toggle="tab"
                                                    data-bs-target="#form" type="button" role="tab"
                                                    aria-controls="billing" aria-selected="false" onClick={() => changeTab('forms')}>Forms</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            {/* Vital Information */}
                                            {
                                                activeTab === 'vitalInfo' &&
                                                <div className="tab-pane fade show active" id="home" role="tabpanel"
                                                    aria-labelledby="home-tab">
                                                    <form className="row">
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">First Name*</label>
                                                            <input type="input" className="form-control" id=""
                                                                placeholder="First Name" {...register("first_name", { required: "true" })} maxLength={20} disabled />
                                                            {errors.first_name && errors.first_name.type === "required" && (<span className="error">First Name is required!</span>)}
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">Last Name*</label>
                                                            <input type="input" className="form-control" id="" placeholder="Last Name" {...register("last_name", { required: "true" })} maxLength={10} disabled />
                                                            {errors.last_name && errors.last_name.type === "required" && (<span className="error">Last Name is required!</span>)}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label for="" className="form-label">Admission date*</label>
                                                            <input type="date" className="form-control" id="" placeholder="mm/dd/yyyy" {...register("admissionDate", { required: "true" })} disabled />
                                                            {errors.admissionDate && errors.admissionDate.type === "required" && (<span className="error">Admission date is required!</span>)}
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">Group home*</label>
                                                            <select className="form-select" aria-label="Default select example" {...register("groupHome", { required: "true" })} disabled>
                                                                <option value=''>Select home</option>
                                                                {homeslist && homeslist?.map((elem) => {
                                                                    return <option value={elem?.id}>{elem?.home_name}</option>
                                                                })
                                                                }
                                                                {errors.groupHome && errors.groupHome.type === "required" && (<span className="error">Home is required!</span>)}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">Street address*</label>
                                                            <input type="input" className="form-control" id=""
                                                                placeholder="Enter address" {...register("streetAddress", { required: "true" })} maxLength={25} disabled />
                                                            {errors.streetAddress && errors.streetAddress.type === "required" && (<span className="error">Street address is required!</span>)}
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">City*</label>
                                                            <input type="text" className="form-control" id="" placeholder="Enter city" {...register("city", { required: "true" })} maxLength={25} disabled />
                                                            {errors.city && errors.city.type === "required" && (<span className="error">City is required!</span>)}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label for="" className="form-label">State*</label>
                                                            <select className="form-select" aria-label="Default select example" {...register("state", { required: "true" })} disabled >
                                                                <option value="">Select State</option>
                                                                {
                                                                    stateData && stateData?.map((elem) => {
                                                                        return <option value={elem?.id}>{elem?.address_state}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.state && errors.state.type === "required" && (<span className="error">State is required!</span>)}
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">Zip code*</label>
                                                            <input type="text" className="form-control" placeholder="Zip code"
                                                                onKeyPress={(event) => {
                                                                    // Allow only numbers (0-9) when typing
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                maxLength={5}
                                                                onPaste={(event) => {
                                                                    // Prevent pasting any non-numeric characters
                                                                    const pastedData = event.clipboardData.getData('text');
                                                                    if (!/^\d{1,5}$/.test(pastedData)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                {...register('zipCode', {
                                                                    pattern: {
                                                                        value: /^\d{5}$/,
                                                                        message: 'ZIP code must be exactly 5 digits',
                                                                    },
                                                                })}
                                                                disabled
                                                            />
                                                            {errors.zipCode && errors.zipCode.type === "required" && (<span className="error">Zipcode is required!</span>)}
                                                            {errors.zipCode && errors.zipCode.type === "pattern" && (<span className="error">{errors?.zipCode?.message}</span>)}
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">Home phone*</label>
                                                            <input type="text" className="form-control" id=""
                                                                placeholder="0000 0000" {...register("phoneNo", { required: "true", pattern: { value: /^\d{10,15}$/, message: "Home phone must be 10 to 15 digits" } })} maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} disabled />
                                                            {errors.phoneNo && errors.phoneNo.type === "required" && (<span className="error">Home phone is required</span>)}
                                                            {errors.phoneNo && errors.phoneNo.type === "pattern" && (<span className="error">{errors?.phoneNo?.message}</span>)}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label for="" className="form-label">Cell phone*</label>
                                                            <input type="input" className="form-control" id="" placeholder="+1 0000 0000" {...register("cellNo", { required: "true", pattern: { value: /^\d{10,15}$/, message: "Cell phone must be 10 to 15 digits" } })} maxLength={15} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} disabled />
                                                            {errors.cellNo && errors.cellNo.type === "required" && (<span className="error">Cell phone is required</span>)}
                                                            {errors.cellNo && errors.cellNo.type === "pattern" && (<span className="error">{errors?.cellNo?.message}</span>)}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label for="" className="form-label">Primary qualifying diagnosis*</label>
                                                            <select className="form-select" aria-label="Default select example" {...register("primaryDiagnosis", { required: "true" })} disabled>
                                                                <option value="">Select one</option>
                                                                {
                                                                    diagnosisList && diagnosisList?.map((elem) => {
                                                                        return <option value={elem?.id}>{elem?.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.primaryDiagnosis && errors.primaryDiagnosis.type === "required" && (<span className="error">Primary qualifying diagnosis is required</span>)}
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label for="" className="form-label">AHSS ID*</label>
                                                            <input type="input" className="form-control" id="" placeholder="Enter AHSS ID" {...register("ahss_id", { required: "true" })} maxLength={20} disabled />
                                                            {errors.ahss_id && errors.ahss_id.type === "required" && (<span className="error">AHSS ID is required!</span>)}
                                                        </div>
                                                        <div className="col-12 mb-3">
                                                            <label for="" className="form-label">Secondary diagnosis (If applicable)</label>
                                                            <input type="text" className="form-control pb-5" id="" placeholder="Enter Secondary diagnosis" {...register("secondaryDiagnosis")} disabled />
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">Gender*</label>
                                                            <select className="form-select" aria-label="Default select example" {...register("gendar", { required: "true" })} disabled>
                                                                <option value=''>Select Gender</option>
                                                                <option value="male">Male</option>
                                                                <option value="female">Female</option>
                                                                <option value="other">Other</option>
                                                            </select>
                                                            {errors.gendar && errors.gendar.type === "required" && (<span className="error">Gender is required</span>)}
                                                        </div>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label for="" className="form-label">Height (in ft)*</label>
                                                            <input type="text" className="form-control" id="" placeholder="Enter Height" {...register("height", { required: "true" })} maxLength={5} disabled />
                                                            {errors.height && errors.height.type === "required" && (<span className="error">Height is required</span>)}
                                                        </div>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label for="" className="form-label">Weight (in pound)*</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="weight"
                                                                placeholder="Enter weight"
                                                                inputMode="decimal"
                                                                pattern="^\d{1,3}(\.\d{2})?$"
                                                                {...register("weight", {
                                                                    required: "Weight is required",
                                                                    pattern: {
                                                                        value: /^\d{1,3}(\.\d{2})?$/,
                                                                        message: "Weight must be a number between 1 and 999, optionally with one decimal place"
                                                                    }
                                                                })}
                                                                maxLength={5}
                                                                disabled
                                                            />
                                                            {errors.weight && errors.weight.type === "required" && (<span className="error">Weight is required</span>)}
                                                        </div>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label for="" className="form-label">Eye color*</label>
                                                            <input type="text" className="form-control " id="" placeholder="Enter eye color" {...register("eyeColor", { required: "true" })} maxLength={10} disabled />
                                                            {errors.eyeColor && errors.eyeColor.type === "required" && (<span className="error">Eye color is required</span>)}
                                                        </div>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label for="" className="form-label">Hair color*</label>
                                                            <input type="text" className="form-control " id="" placeholder="Enter hair color" {...register("hairColor", { required: "true" })} maxLength={10} disabled />
                                                            {errors.hairColor && errors.hairColor.type === "required" && (<span className="error">Hair color is required</span>)}
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">Race*</label>
                                                            <select className="form-select" aria-label="Default select example" {...register("race", { required: "true" })} disabled>
                                                                <option value=''>Select race</option>
                                                                {
                                                                    raceList && raceList?.map((elem) => {
                                                                        return <option value={elem?.id}>{elem?.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.race && errors.race.type === "required" && (<span className="error">Race is required</span>)}
                                                        </div>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label for="" className="form-label">Birthplace*</label>
                                                            <input type="text" className="form-control " id=""
                                                                placeholder="Enter birthPlace" {...register("birthPlace", { required: "true" })} maxLength={15} disabled />
                                                            {errors.birthPlace && errors.birthPlace.type === "required" && (<span className="error">Birthplace is required</span>)}
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">Citizenship*</label>
                                                            <select className="form-select" aria-label="Default select example" {...register("citizenship", { required: "true" })} disabled>
                                                                <option value=''>American indian/Alaska native</option>
                                                                {
                                                                    sortedCountryData && sortedCountryData?.map((elem) => {
                                                                        return <option value={elem?.id}>{elem?.country}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.citizenship && errors.citizenship.type === "required" && (<span className="error">Citizenship is required</span>)}
                                                        </div>
                                                        <div className="col-md-6 col-12 mb-3">
                                                            <label for="" className="form-label">Primary language*</label>
                                                            <select className="form-select" aria-label="Default select example" {...register("primaryLanguage", { required: "true" })} disabled>
                                                                <option value=''>Select Language</option>
                                                                {LanguageList && LanguageList?.map((elem) => {
                                                                    return <option value={elem?.id}>{elem?.name}</option>
                                                                })
                                                                }
                                                            </select>
                                                            {errors.primaryLanguage && errors.primaryLanguage.type === "required" && (<span className="error">Primary language is required</span>)}
                                                        </div>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label for="" className="form-label">Religious preference*</label>
                                                            <input type="text" className="form-control " id=""
                                                                placeholder="Enter Religious" {...register("religiousPreference", { required: "true" })} disabled />
                                                            {errors.religiousPreference && errors.religiousPreference.type === "required" && (<span className="error">Religious preference is required</span>)}
                                                        </div>

                                                        {userPermissions?.includes('Edit') &&
                                                            <div className="col-md-12 login-btn vital_btn">
                                                                <button type="button" className="btn btn-primary" onClick={() => EditVitalInfo()}>Edit or View complete
                                                                    vital information <svg width="10" height="10"
                                                                        viewBox="0 0 10 10" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M0.833313 9.16659L9.16665 0.833252M9.16665 0.833252H0.833313M9.16665 0.833252V9.16659"
                                                                            stroke="#ffffff" stroke-width="1.66667"
                                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        }
                                                    </form>
                                                </div>
                                            }
                                            {/* MAR */}
                                            {
                                                activeTab === 'MAR' && <MAR />
                                            }
                                            {/* Appointment */}
                                            {
                                                activeTab === 'appointment' && <AppointmentMember member_id={id?.id} />
                                            }
                                            {/* Communication */}
                                            {
                                                activeTab === 'communication' && <Communication member_id={id?.id} />
                                            }
                                            {/* Documents */}
                                            {
                                                activeTab === 'documents' && <Documents />
                                            }
                                        </div>
                                        {/* Forms */}
                                        {
                                            activeTab === 'forms' && <Forms member_id={id?.id} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main >
            {/* Delete Member */}
            <DeleteMember setDeleteModal={setDeleteModal} deleteModal={deleteModal} type='delete_single' id={id?.id} />
            <div className="modal fade" id="upload-pop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg permision-popup">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Upload doucment
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mvital_upldcont">
                            <div className="upload-btn-wrapper">
                                <button className="vitalu_cont">
                                    <div className="upload_icon">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.6668 1.89111V5.33323C11.6668 5.79994 11.6668 6.0333 11.7577 6.21156C11.8376 6.36836 11.965 6.49584 12.1218 6.57574C12.3001 6.66656 12.5335 6.66656 13.0002 6.66656H16.4423M13.3335 10.8332H6.66683M13.3335 14.1665H6.66683M8.3335 7.49984H6.66683M11.6668 1.6665H7.3335C5.93336 1.6665 5.2333 1.6665 4.69852 1.93899C4.22811 2.17867 3.84566 2.56112 3.60598 3.03153C3.3335 3.56631 3.3335 4.26637 3.3335 5.6665V14.3332C3.3335 15.7333 3.3335 16.4334 3.60598 16.9681C3.84566 17.4386 4.22811 17.821 4.69852 18.0607C5.2333 18.3332 5.93336 18.3332 7.3335 18.3332H12.6668C14.067 18.3332 14.767 18.3332 15.3018 18.0607C15.7722 17.821 16.1547 17.4386 16.3943 16.9681C16.6668 16.4334 16.6668 15.7333 16.6668 14.3332V6.6665L11.6668 1.6665Z"
                                                stroke="#344054" stroke-width="1.66667" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>

                                    </div>
                                    <div className="vital_text_png"><span>Click to upload</span> or drag and drop <br />PDF, PNG,
                                        JPEG, JPG, DOCX (max. 25mb)</div>
                                </button>
                                <input type="file" name="myfile" />
                            </div>
                            <div>
                                <div className="progress_bar">
                                    <div className="progress_pdf">
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
                                                stroke="#D0D5DD" stroke-width="1.5" />
                                            <path d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5" stroke="#D0D5DD"
                                                stroke-width="1.5" />
                                            <path
                                                d="M1 20C1 18.8954 1.89543 18 3 18H25C26.1046 18 27 18.8954 27 20V32C27 33.1046 26.1046 34 25 34H3C1.89543 34 1 33.1046 1 32V20Z"
                                                fill="#D92D20" />
                                            <path
                                                d="M4.8323 30V22.7273H7.70162C8.25323 22.7273 8.72316 22.8326 9.11142 23.0433C9.49967 23.2517 9.7956 23.5417 9.9992 23.9134C10.2052 24.2827 10.3082 24.7088 10.3082 25.1918C10.3082 25.6747 10.204 26.1009 9.99565 26.4702C9.78732 26.8395 9.48547 27.1271 9.09011 27.3331C8.69712 27.5391 8.22127 27.642 7.66255 27.642H5.83372V26.4098H7.41397C7.7099 26.4098 7.95375 26.3589 8.14551 26.2571C8.33964 26.1529 8.48405 26.0097 8.57875 25.8274C8.67581 25.6428 8.72434 25.4309 8.72434 25.1918C8.72434 24.9503 8.67581 24.7396 8.57875 24.5597C8.48405 24.3774 8.33964 24.2365 8.14551 24.1371C7.95138 24.0353 7.70517 23.9844 7.40687 23.9844H6.36994V30H4.8323ZM13.885 30H11.3069V22.7273H13.9063C14.6379 22.7273 15.2676 22.8729 15.7955 23.1641C16.3235 23.4529 16.7295 23.8684 17.0136 24.4105C17.3 24.9527 17.4433 25.6013 17.4433 26.3565C17.4433 27.1141 17.3 27.7652 17.0136 28.3097C16.7295 28.8542 16.3211 29.272 15.7884 29.5632C15.2581 29.8544 14.6237 30 13.885 30ZM12.8445 28.6825H13.8211C14.2757 28.6825 14.658 28.602 14.9681 28.4411C15.2806 28.2777 15.515 28.0256 15.6713 27.6847C15.8299 27.3414 15.9092 26.8987 15.9092 26.3565C15.9092 25.8191 15.8299 25.38 15.6713 25.0391C15.515 24.6982 15.2818 24.4472 14.9717 24.2862C14.6615 24.1252 14.2792 24.0447 13.8247 24.0447H12.8445V28.6825ZM18.5823 30V22.7273H23.3976V23.995H20.1199V25.728H23.078V26.9957H20.1199V30H18.5823Z"
                                                fill="white" />
                                        </svg>
                                    </div>
                                    <div className="progres_file">
                                        <div className="progres_file_cont">
                                            <h4>Full file review 2023.pdf<span>200 KB</span></h4>
                                            <div className="upload_pdf_del">
                                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9.66667 4.00016V3.46683C9.66667 2.72009 9.66667 2.34672 9.52134 2.06151C9.39351 1.81063 9.18954 1.60665 8.93865 1.47882C8.65344 1.3335 8.28007 1.3335 7.53333 1.3335H6.46667C5.71993 1.3335 5.34656 1.3335 5.06135 1.47882C4.81046 1.60665 4.60649 1.81063 4.47866 2.06151C4.33333 2.34672 4.33333 2.72009 4.33333 3.46683V4.00016M5.66667 7.66683V11.0002M8.33333 7.66683V11.0002M1 4.00016H13M11.6667 4.00016V11.4668C11.6667 12.5869 11.6667 13.147 11.4487 13.5748C11.2569 13.9511 10.951 14.2571 10.5746 14.4488C10.1468 14.6668 9.58677 14.6668 8.46667 14.6668H5.53333C4.41323 14.6668 3.85318 14.6668 3.42535 14.4488C3.04903 14.2571 2.74307 13.9511 2.55132 13.5748C2.33333 13.147 2.33333 12.5869 2.33333 11.4668V4.00016"
                                                        stroke="black" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>

                                            </div>
                                        </div>
                                        <div className="progress_line">
                                            <div className="progres_line_blue"></div>
                                            <h4>100%</h4>
                                        </div>
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>
                            <form action="">
                                <label for="" className="form-label">Category</label>
                                <select className="form-select" aria-label="Default select example">
                                    <option selected="">Diana Milone</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <div className="up_vitalm_btn">
                                    <button type="button">Cancel</button>
                                    <button type="button">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AddMember id={id?.id}  /> */}

        </div>

    )
}

export default EditMember;